<div class="pagina-detalhes">
  <div class="sub-header container">
    <span class="sub-header-titulo">PARCERIAS</span>
  </div>
  <div class="descricao-parceria container animacao">
    <div class="lista-descricao-parceria">
      <div class="grid descricao justify-content-center flex-row-reverse">
        <div class="col descricao-imagem">
          <img src="assets/imagens/parceria.png" />
        </div>
        <div class="col descricao-detalhes">
          <span class="descricao-titulo">Nasce uma parceria</span>
          <!-- <p class="item-list">
            <i class="pi pi-check texto-primary font-bold"></i>Uma licença do G3Soft dá direito a
            1 (um) computador habilitado por mês. Utilizamos o modelo de
            "mensalidade pré-paga", com validade até o último dia do mês
            corrente.
          </p> -->
          <p class="item-list">
            <i class="pi pi-check texto-primary font-bold"></i>Você comercializa
            as licenças de nossos produtos e nossa parceria se inicia no momento
            em que houver ao menos uma licença ativada.
          </p>
          <p class="item-list">
            <i class="pi pi-check texto-primary font-bold"></i>Ao tornar-se
            parceiro, você tem direito a utilizar nosso sistema gratuitamente em
            sua empresa.
          </p>
        </div>
      </div>
      <div class="grid descricao justify-content-center">
        <div class="col descricao-imagem">
          <img src="assets/imagens/liberdade.png" />
        </div>
        <div class="col descricao-detalhes">
          <span class="descricao-titulo">Isso é liberdade</span>
          <p class="item-list">
            <i class="pi pi-check texto-primary font-bold"></i>Você adquire
            licenças por um custo fixo, oferece para seus clientes e define a
            margem de lucro que achar melhor.
          </p>
          <p class="item-list">
            <i class="pi pi-check texto-primary font-bold"></i>Implante uma
            licença quando quiser. Libere-a quando quiser. Se não usar, o valor
            fica como crédito para o próximo mês.
          </p>
          <p class="item-list">
            <i class="pi pi-check texto-primary font-bold"></i>Sem anuidade, sem
            prazo mínimo e sem multas. Como são licenças pré-pagas você pode
            cancelar no mês que desejar.
          </p>
          <p class="item-list">
            <i class="pi pi-check texto-primary font-bold"></i>
            <span
              >Tudo controlado pelo seu <b>Portal de Licenciamento.</b>
            </span>
          </p>
        </div>
      </div>
      <div class="grid descricao justify-content-center flex-row-reverse">
        <div class="col descricao-imagem">
          <img src="assets/imagens/confianca.png" />
        </div>
        <div class="col descricao-detalhes">
          <span class="descricao-titulo">Confiança é tudo</span>
          <p class="item-list">
            <i class="pi pi-check texto-primary font-bold"></i>Uma vez com a
            licença em dia, você e seus clientes têm acesso à todas as
            modificações do sistema, trabalhando com segurança e de acordo com a
            legislação vigente.
          </p>
          <p class="item-list">
            <i class="pi pi-check texto-primary font-bold"></i>Todas as
            atualizações sem custos adicionais, sejam elas corretivas,
            implementativas ou obrigatórias.
          </p>
          <p class="item-list">
            <i class="pi pi-check texto-primary font-bold"></i>Essa é a nossa
            estratégia: oferecer uma ferramenta robusta por um valor
            surpreendente.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="seja-parceiro container">
    <span class="seja-parceiro-titulo">Vamos nos tornar parceiros?</span>
    <p-button
      label="SEJA PARCEIRO (EM BREVE)"
      (onClick)="showBasicDialog()"
      class="primary-button-outline"
    ></p-button>
  </div>
  <div class="vantagens container animacao">
    <div class="grid vantagem">
      <div class="col vantagem-detalhes">
        <div class="vantagem-imagem">
          <img src="assets/imagens/lucratividade.png" alt="" />
        </div>
        <span class="vantagem-titulo">Lucratividade</span>
        <p class="item-list">
          <i class="pi pi-check texto-primary font-bold"></i>Margem de lucro
          livre
        </p>
        <p class="item-list">
          <i class="pi pi-check texto-primary font-bold"></i>Sem interferências
          no seu modo de negociar
        </p>
        <p class="item-list">
          <i class="pi pi-check texto-primary font-bold"></i>Valores reduzidos
          para licenças adicionais
        </p>
        <p class="item-list">
          <i class="pi pi-check texto-primary font-bold"></i>Licenças não
          ativadas voltam em forma de crédito
        </p>
        <p class="item-list">
          <i class="pi pi-check texto-primary font-bold"></i>Sem cobranças
          extras pelas atualizações
        </p>
        <p class="item-list">
          <i class="pi pi-check texto-primary font-bold"></i>Não precisa
          adquirir pacotes nem equipamentos
        </p>
      </div>
      <div class="col vantagem-detalhes">
        <div class="vantagem-imagem">
          <img src="assets/imagens/liberdade_2.png" alt="" />
        </div>
        <span class="vantagem-titulo">Liberdade</span>
        <p class="item-list">
          <i class="pi pi-check texto-primary font-bold"></i>Sem fidelidade,
          cancele quando quiser
        </p>
        <p class="item-list">
          <i class="pi pi-check texto-primary font-bold"></i>Sem prazos, multas
          ou anuidades
        </p>
        <p class="item-list">
          <i class="pi pi-check texto-primary font-bold"></i>Enquanto tiver um
          cliente pagante, você usa nossos sistemas gratuitamente em sua empresa
        </p>
        <p class="item-list">
          <i class="pi pi-check texto-primary font-bold"></i>Direito à todas as
          atualizações do sistema
        </p>
        <p class="item-list">
          <i class="pi pi-check texto-primary font-bold"></i>Não exigimos
          exclusividade
        </p>
        <p class="item-list">
          <i class="pi pi-check texto-primary font-bold"></i>Raramente
          reajustamos valores
        </p>
      </div>
      <div class="col vantagem-detalhes">
        <div class="vantagem-imagem">
          <img src="assets/imagens/apoio.png" alt="" />
        </div>
        <span class="vantagem-titulo">Apoio</span>
        <p class="item-list">
          <i class="pi pi-check texto-primary font-bold"></i>Portal de
          licenciamento
        </p>
        <p class="item-list">
          <i class="pi pi-check texto-primary font-bold"></i>Treinamento
        </p>
        <p class="item-list">
          <i class="pi pi-check texto-primary font-bold"></i>Suporte
        </p>
        <p class="item-list">
          <i class="pi pi-check texto-primary font-bold"></i>Acesso remoto
        </p>
        <p class="item-list">
          <i class="pi pi-check texto-primary font-bold"></i>Atendimento via
          telefone e chat
        </p>
        <p class="item-list">
          <i class="pi pi-check texto-primary font-bold"></i>Importação da base
          de dados
        </p>
        <p class="item-list">
          <i class="pi pi-check texto-primary font-bold"></i>Base de
          conhecimento
        </p>
      </div>
    </div>
  </div>
  <p-dialog
    header=" "
    [(visible)]="displayBasic"
    [modal]="true"
    [style]="{ width: isDesktop ? '30vw' : '90vw' }"
    [draggable]="true"
    [resizable]="true"
    (onShow)="resetForm()"
  >
    <div class="form mx-5" [formGroup]="form">
      <div class="dialog-header">
        <span class="titulo">Quer saber mais?</span>
        <span class="subtitulo">Solicite retorno do setor comercial</span>
      </div>

      <div class="formgrid grid">
        <div class="field col-12">
          <input
            placeholder="Nome"
            pInputText
            formControlName="nome"
            type="text"
            class="inputfield w-full"
            [ngClass]="{
              'ng-invalid ng-dirty': verificarCampoInvalido(form, 'nome')
            }"
          />
          <small
            *ngIf="verificarCampoInvalido(form, 'nome', 'required')"
            class="p-error"
            >Campo obrigatório<br
          /></small>
        </div>
        <div class="field col-12">
          <input
            placeholder="E-mail"
            pInputText
            formControlName="email"
            type="text"
            class="inputfield w-full"
            [ngClass]="{
              'ng-invalid ng-dirty': verificarCampoInvalido(form, 'email')
            }"
          />
          <small
            *ngIf="verificarCampoInvalido(form, 'email', 'required')"
            class="p-error"
            >Campo obrigatório<br
          /></small>
        </div>
        <div class="field col-12">
          <input
            placeholder="CNPJ"
            pInputText
            formControlName="documento"
            [mask]="maskDocumento"
            type="text"
            class="inputfield w-full"
            [ngClass]="{
              'ng-invalid ng-dirty': verificarCampoInvalido(form, 'documento')
            }"
          />
          <small
            *ngIf="verificarCampoInvalido(form, 'documento', 'required')"
            class="p-error"
            >Campo obrigatório<br
          /></small>
        </div>
        <div class="field col-12">
          <input
            placeholder="Telefone"
            pInputText
            formControlName="telefone"
            type="text"
            [mask]="maskTelefone"
            class="inputfield w-full"
            [ngClass]="{
              'ng-invalid ng-dirty': verificarCampoInvalido(form, 'telefone')
            }"
          />
          <small
            *ngIf="verificarCampoInvalido(form, 'telefone', 'required')"
            class="p-error"
            >Campo obrigatório<br
          /></small>
        </div>
      </div>
      <p-button
        (onClick)="enviar()"
        [loading]="loading"
        label="Enviar"
        class="primary-button ml-1"
      ></p-button>
    </div>
    <ng-template pTemplate="footer">
      <div class="dialog-footer">
        <div class="email">
          <a [href]="hrefContatoEmail">{{ contatoEmail }}</a>
        </div>
      </div>
    </ng-template>
  </p-dialog>
</div>
