import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AppFacade} from '@app/domain/facades/app.facade';
import {DeviceDetectorFacade} from '@app/domain/facades/device-detector.facate';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-fale-conosco-dialog',
  templateUrl: './fale-conosco-dialog.component.html',
  styleUrls: ['./fale-conosco-dialog.component.scss']
})
export class FaleConoscoDialogComponent implements OnInit {
  @Input() showDialog: boolean;
  @Output() showDialogChange = new EventEmitter<boolean>();

  form: UntypedFormGroup;
  loading = false;

  maskDocumento = '00.000.000/0000-00||000.000.000-00';
  maskTelefone = '(00) 0000-0000||(00) 00000-0000';

  get isDesktop() {
    return this.deviceDetectorFacade.isDesktop;
  }
  get contatoEmail() {
    return this.appFacade.contatoEmail;
  }
  get hrefContatoEmail() {
    return `mailto:${this.contatoEmail}`;
  }
  constructor(
      private fb: UntypedFormBuilder,
      private deviceDetectorFacade: DeviceDetectorFacade,
      private appFacade: AppFacade,
      private messageService: MessageService,
  ) {}

  private criarForm() {
    this.form = this.fb.group({
      nome: [null, Validators.required],
      email: [null, Validators.required],
      documento: [null, Validators.required],
      telefone: [null, Validators.required],
    });
  }
  async enviar() {
    if (this.form.valid) {
      this.loading = true;
      this.appFacade
          .enviarEmailParaContato(
              this.form.value.nome,
              this.form.value.email,
              this.form.value.documento,
              this.form.value.telefone,
              'Solicitação de Contato - Portal G3Soft',
              )
          .then(() => {
            this.aoFechar();
            this.resetForm();
            this.messageService.add({
              severity: 'success',
              summary: 'Sucesso',
              detail: 'E-mail enviado com sucesso',
            });
          })
          .catch(() => {
            this.messageService.add({
              severity: 'warning',
              summary: 'Atenção',
              detail: 'E-mail não enviado, tente novamente mais tarde',
            });
          })
          .finally(() => {
            this.loading = false;
          });
    } else {
      this.setarCamposInvalidos();
    }
  }
  ngOnInit() {
    this.criarForm();
  }
  verificarCampoInvalido(
      formGroup: UntypedFormGroup, nome: string, validador?: string): boolean {
    if (validador) {
      return (
          this.formControl(nome, formGroup).hasError(validador) &&
          this.formControl(nome, formGroup).touched);
    } else {
      return (
          this.formControl(nome, formGroup).errors &&
          this.formControl(nome, formGroup).touched);
    }
  }
  formControl(name: string, formGroup: UntypedFormGroup) {
    return formGroup.get(name);
  }
  resetForm() {
    this.form.reset();
  }
  setarCamposInvalidos() {
    Object.keys(this.form.controls).forEach((field) => {
      const control = this.form.get(field);
      control.markAsTouched({onlySelf: true});
    });
  }
  aoFechar() {
    this.showDialogChange.emit(false);
  }
}
