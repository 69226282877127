import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppRoutingModule} from '@app/app-routing.module';

@NgModule({
  imports: [
    HttpClientModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    ReactiveFormsModule,
  ],
  exports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
  ],
  declarations: [],
  providers: [
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'BRL',
    },
    {provide: LOCALE_ID, useValue: 'pt-BR'},
  ],
})
export class CoreModule {
}
