<div class="footer container">
  <div class="links">
    <div class="grid grid-links">
      <div class="col">
        <div class="logo">
          <img src="assets/imagens/g3-logo-300x90.png" />
        </div>
        <div class="descricao-empresa">
          <span
            >Trabalhamos com sistemas para empresas de pequeno e médio
            porte.</span
          >
        </div>
      </div>
      <div class="col link-coluna">
        <span class="link-titulo">Fale Conosco</span>
        <span class="link"
          ><a routerLink="" (click)="op.toggle($event)">Suporte</a></span
        >
        <span class="link"><a routerLink="/central-de-ajuda">Central de ajuda</a></span>
        <span class="link"><a routerLink="/desenvolvedores">Desenvolvedores</a></span>
      </div>
      <div class="col link-coluna">
        <span class="link-titulo">LGPD</span>
        <span class="link"><a routerLink="/termos-de-uso">Termos de uso</a></span>
        <span class="link"><a routerLink="/politica-privacidade">Politica de privacidade</a></span>
        <span class="link"><a routerLink="/tratamento-de-dados">Tratamento de dados</a></span>
      </div>
      <div class="col link-coluna">
        <span class="link-titulo">Mapa do site</span>
        <span class="link"><a routerLink="/solucoes">Soluções</a></span>
        <span class="link"><a routerLink="/funcionalidades">Funcionalidades</a></span>
        <span class="link"><a routerLink="/segmentos">Segmentos</a></span>
        <span class="link"><a routerLink="/parcerias">Parcerias</a></span>
        <span class="link"><a routerLink="/fabrica-software">Fábrica de software</a></span>
      </div>
      <div class="col link-coluna">
        <span class="link-titulo">Contato</span>
        <div class="flex flex-column contato">
          <span class="contato-endereco"
            >Rua Celso Garcia, 11 - Centro, Batatais - SP
          </span>
          <a href="tel:(16) 99224-9258" class="contato-fone">(16) 99224-9258</a>
          <a [href]="hrefContatoEmail" class="contato-email">{{ contatoEmail }}</a>
        </div>
        <span class="link-titulo mt-5 mb-2">Redes sociais</span>
        <div class="flex text-center">
          <div class="social">
            <a href="https://www.instagram.com/g3softecnologia/" target="_blank"
              ><i class="pi pi-instagram"></i
            ></a>
          </div>
          <div class="social">
            <a href="https://www.facebook.com/g3softecnologia/" target="_blank"
              ><i class="pi pi-facebook"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <span class="copyright"
    >G3Soft - © Copyright 2022. Todos os direitos reservados.</span
  >
  <p-overlayPanel #op>
    <ng-template pTemplate>
      <div class="flex flex-column">
        <h2 class="mt-1 text-center">Suporte</h2>
        <span class="mt-1 ml-3">
          Esclareça suas dúvidas em tempo real com um de nossos analistas.
        </span>
        <span class="mt-1 ml-3">
          Para solicitações de suporte técnico, sugestões e outros assuntos
          utilize a abertura de chamado.
        </span>
        <span class="mt-1 ml-3 font-bold">
          *Atendimento em horário comercial.
        </span>
      </div>
      <div class="inline-flex mt-2">
        <a
          href="https://api.whatsapp.com/send?phone=5516992249258"
          target="_blank"
          class="container-suporte text-center"
        >
          <div class="middle">
            <img src="assets/imagens/g3-support-whats-614x617.png" width="80" />
            <h3>Suporte via WhatsApp</h3>
          </div>
        </a>
        <a
          href="https://api.whatsapp.com/send?phone=5516999613637"
          target="_blank"
          class="container-suporte text-center"
        >
          <div class="middle">
            <img src="assets/imagens/g3-cart-whats-614x617.png" width="80" />
            <h3>Vendas via WhatsApp</h3>
          </div>
        </a>
      </div>
    </ng-template>
  </p-overlayPanel>
</div>
