import {Component, OnInit} from '@angular/core';
import {AppFacade} from '@app/domain/facades/app.facade';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  get contatoEmail() {
    return this.appFacade.contatoEmail;
  }
  get hrefContatoEmail() {
    return `mailto:${this.contatoEmail}`;
  }
  constructor(private appFacade: AppFacade) {}
  ngOnInit() {}
}
