<div class="section slide-option">
  <div class="infinite highway-slider">
    <div class="container highway-barrier">
      <ul class="highway-lane">
        <li class="highway-car"><img src="assets/imagens/segmentos/cards/1.png"></li>
        <li class="highway-car"><img src="assets/imagens/segmentos/cards/2.png"></li>
        <li class="highway-car"><img src="assets/imagens/segmentos/cards/3.png"></li>
        <li class="highway-car"><img src="assets/imagens/segmentos/cards/4.png"></li>
        <li class="highway-car"><img src="assets/imagens/segmentos/cards/5.png"></li>
        <li class="highway-car"><img src="assets/imagens/segmentos/cards/6.png"></li>
        <li class="highway-car"><img src="assets/imagens/segmentos/cards/7.png"></li>
        <li class="highway-car"><img src="assets/imagens/segmentos/cards/8.png"></li>
        <li class="highway-car"><img src="assets/imagens/segmentos/cards/9.png"></li>
        <li class="highway-car"><img src="assets/imagens/segmentos/cards/10.png"></li>
        <li class="highway-car"><img src="assets/imagens/segmentos/cards/11.png"></li>
        <li class="highway-car"><img src="assets/imagens/segmentos/cards/12.png"></li>
        <li class="highway-car"><img src="assets/imagens/segmentos/cards/13.png"></li>
        <li class="highway-car"><img src="assets/imagens/segmentos/cards/14.png"></li>
        <li class="highway-car"><img src="assets/imagens/segmentos/cards/15.png"></li>
        <li class="highway-car"><img src="assets/imagens/segmentos/cards/16.png"></li>
        <li class="highway-car"><img src="assets/imagens/segmentos/cards/17.png"></li>
        <li class="highway-car"><img src="assets/imagens/segmentos/cards/18.png"></li>
        <li class="highway-car"><img src="assets/imagens/segmentos/cards/19.png"></li>
        <li class="highway-car"><img src="assets/imagens/segmentos/cards/20.png"></li>
      </ul>
    </div>
  </div>
</div>
