<div class="pagina-detalhes">
  <div class="sub-header container">
    <span class="sub-header-titulo">CENTRAL DE AJUDA</span>
  </div>
  <div class="container animacao">
    <h1 class="texto-primary">PÁGINA EM CONSTRUÇÃO</h1>
    <h3 class="texto-primary">Esta página ainda não está pronta, mas em breve você poderá acessá-la. </h3>
    <img class="m-6" src="assets/imagens/pagina-construcao-800x800.png" width="40%"/>
  </div>
</div>
