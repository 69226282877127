import { Injectable } from '@angular/core';
import { CookieService } from '@app/infra/services/cookies.service';
import { CookieState } from '@app/infra/states/cookies.states';

@Injectable({ providedIn: 'root' })
export class CookieFacade {
  get cookiesAceito() {
    return this.state.cookieAceito;
  }
  constructor(private state: CookieState, private service: CookieService) {}
  setarCookie() {
    this.service.setarCookieAceito(true);
  }
}
