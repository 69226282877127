import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class EmailService {
  constructor(private client: HttpClient) {}

  enviarEmailParaContato(
      nome: string,
      email: string,
      documento: string,
      telefone: string,
      to: string,
      assunto: string,
  ) {
    return this.client
        .post(`https://g3ultron.com.br/api/v1/Email/EnviarFaleConosco`, {
          to: to,
          assunto: assunto,
          dados: {
            nome: nome,
            email: email,
            documento: documento,
            telefone: telefone,
          }
        })
        .toPromise();
  }
}
