<a
  href="https://api.whatsapp.com/send?phone=5516992249258"
  class="whatsapp-button"
  target="_blank"
  style="position: fixed; right: 15px; bottom: 125px"
>
  <img
    src="https://i.ibb.co/VgSspjY/whatsapp-button.png"
    alt="botão whatsapp"
    pTooltip="Vendas pelo Whatsapp"
    tooltipStyleClass="text-center"
  />
</a>

<a
  href="https://api.whatsapp.com/send?phone=5516992249258"
  class="whatsapp-button"
  target="_blank"
  style="position: fixed; right: 15px; bottom: 65px"
>
  <img
    src="assets/imagens/support-icon.png"
    alt="botão whatsapp"
    pTooltip="Atendimento ao cliente"
    tooltipStyleClass="text-center"
  />
</a>
