import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-fale-conosco',
  templateUrl: './fale-conosco.component.html',
  styleUrls: ['./fale-conosco.component.scss']
})
export class FaleConoscoSectionComponent {
  mostrarDialogFaleConosco: boolean = false;

  abrirDialogFaleConosco() {
    this.mostrarDialogFaleConosco = true;
  }
}
