import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-infinite-carousel-segments',
  templateUrl: './infinite-carousel-segments.component.html',
  styleUrls: ['./infinite-carousel-segments.component.scss'],
})
export class InfiniteCarouselSegmentsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
