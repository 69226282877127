<div class="fale-conosco container animacao">
  <div class="content">
    <div class="grid content-grid justify-content-center flex-row-reverse">
      <div class="col-12 xl:col-6 content-imagem">
        <img src="assets/imagens/home/4.png" />
      </div>
      <div class="col-12 xl:col-6 content-detalhes">
        <span class="content-titulo">Fale Conosco</span>
        <span class="content-texto">
          Confie na G3Soft! Melhore o desempenho de sua empresa com o mais
          completo sistema de gestão empresarial. Oferecemos as soluções ideais
          e totalmente integradas para tornar as operações da sua empresa muito
          mais simples e impulsionando suas vendas! Peça um orçamento.
        </span>
        <p-button
          label="Entre em contato"
          class="on-light-button-outline mt-5"
          (onClick)="abrirDialogFaleConosco()"
        ></p-button>
      </div>
    </div>
  </div>
</div>

<app-fale-conosco-dialog
  [(showDialog)]="mostrarDialogFaleConosco"
></app-fale-conosco-dialog>
