<div class="pagina-detalhes">
  <div class="sub-header container">
    <span class="sub-header-titulo">POLÍTICA DE PRIVACIDADE</span>
  </div>
  <div class="container animacao text-left">
    <h2 class="texto-dark-minus-1">Aviso de política de privacidade</h2>
    <p class="text-justify">
      A <b>G3soft</b>, preza pela segurança dos seus dados, pelo respeito a sua
      privacidade e pela transparência com você e, por isso, dedicamos este
      documento para explicar como os seus dados pessoais serão tratados por nós
      e quais são as medidas que aplicamos para mantê-los seguros.
    </p>
    <p class="text-justify">
      Antes de mais nada, queremos nos apresentar, nós somos a <b>G3soft</b>,
      inscrita no CNPJ, com sede na Cidade de Batatais, Estado do São Paulo, na
      Rua Celso Garcia, n. 31 – Salas 01, 02 e 03, centro, segundo a definição
      trazida pela Lei Geral de Proteção de Dados “LGPD” (Lei Federal nº
      13.709/2018), na maior parte do tempo seremos o controlador das suas
      informações, sendo assim, responsáveis por definir o que acontece com
      estes dados e por protegê-los.
    </p>
    <p class="text-justify">
      Para facilitar a compreensão deste aviso de nossa política, todas as vezes
      que encontrar os termos “G3soft”, “nós” ou “nossos”, estamos nos referindo
      ao controlador dos seus dados pessoais, ou seja, as empresas que trabalham
      junto a G3soft ou simplesmente G3soft e, todas as vezes que ler “usuário”,
      “você”, “seu” ou “sua”, nos referimos a você, nosso cliente ou usuário de
      nossas plataformas.
    </p>
    <h3 class="texto-dark-minus-1">Quais dados são coletados pela G3soft</h3>
    <p class="text-justify">
      Durante sua experiência em um de nossos sites, blogs ou sistemas da
      G3soft, podemos coletar diferentes tipos de dados pessoais de forma
      automática com o objetivo de conferência, monitoramento e controle, ou
      fornecidas diretamente por você, como, por exemplo, para a realização de
      seu cadastro. Veja abaixo quais dados pessoais nós podemos coletar em cada
      situação:
    </p>
    <h4 class="texto-dark-minus-1">Durante o cadastro:</h4>
    <ul>
      <li>Nome completo;</li>
      <li>Número de CPF;</li>
      <li>Endereço de e-mail;</li>
      <li>Número de celular;</li>
      <li>Data de nascimento;</li>
      <li>Dados referentes aos seus endereços.</li>
    </ul>
    <h4 class="texto-dark-minus-1">Durante a forma de pagamento:</h4>
    <ul>
      <li>Endereço de cobrança/entrega.</li>
    </ul>
    <p class="text-justify">
      Abaixo apresentamos as informações que poderão ser coletadas mesmo que
      você não esteja cadastrado nas nossas plataformas:
    </p>
    <h4 class="texto-dark-minus-1">Durante a navegação nas plataformas:</h4>
    <ul>
      <li>Endereço de IP;</li>
      <li>Informações sobre o dispositivo utilizado para a navegação;</li>
      <li>Produtos e categorias pesquisados ou visualizados;</li>
      <li>Contagem de visualizações;</li>
      <li>Páginas visitadas em outros sites.</li>
    </ul>
    <h4 class="texto-dark-minus-1">
      Outras informações que poderão ser coletadas:
    </h4>
    <ul>
      <li>Informações de login;</li>
      <li>
        Informações sobre você que se tornaram públicas por você ou que estejam
        disponíveis publicamente;
      </li>
      <li>
        Informações que coletamos de terceiros como, por exemplo, complementos
        dos seus dados cadastrais (nome, filiação, data de nascimento, CPF,
        número de telefone, endereço, entre outros).
      </li>
      <li>
        Informações fornecidas por você, voluntariamente, como por meio de
        comunicações com a G3soft por telefone, mensagens ou em redes sociais.
      </li>
      <li>
        Cópias de documentos enviados por você como comprovante de residência,
        dados bancários ou cópia de um documento de identidade, geralmente
        utilizados para comprovação de seu cadastro ou validação de sua
        identidade.
      </li>
    </ul>
    <h3 class="texto-dark-minus-1">
      Como nós utilizamos os seus dados pessoais
    </h3>
    <h4 class="texto-dark-minus-1">Dados cadastrais:</h4>
    <ul>
      <li>
        Para viabilizar a prestação de diferentes serviços disponíveis em nossos
        sistemas, nos aplicativos da marca da G3soft (eGestor e NfeMais);
      </li>
      <li>
        Para realizar o atendimento de solicitações e dúvidas em nossa Central
        de Atendimento;
      </li>
      <li>Para identificar corretamente o usuário;</li>
      <li>Para enviar comunicações de ofertas;</li>
      <li>
        Para entrar em contato com você, quando necessário. Esse contato pode
        contemplar diversos assuntos, como comunicação sobre promoções e
        ofertas, respostas a dúvidas, respostas de reclamações e solicitações,
        atualizações das suas requisições de suporte;
      </li>
      <li>Para auxiliar no diagnóstico e solução de problemas técnicos.</li>
    </ul>
    <h4 class="texto-dark-minus-1">Dados de Navegação:</h4>
    <ul>
      <li>Para auxiliar no diagnóstico e solução de problemas técnicos;</li>
      <li>
        Para desenvolver novas funcionalidades e melhorias, aprimorando a sua
        experiência com nossos serviços disponíveis.
      </li>
    </ul>
    <h3 class="texto-dark-minus-1">
      Com quem nós podemos compartilhar os seus dados pessoais
    </h3>
    <p class="text-justify">
      Para a execução das atividades apresentadas, sempre que necessário, nós
      poderemos compartilhar os seus dados pessoais com outras empresas que
      trabalham conosco para atendimento de suas demandas, com prestadores de
      serviço, parceiros ou com órgãos reguladores. Jamais comercializamos dados
      pessoais. Apresentamos a seguir um resumo destas possibilidades:
    </p>
    <h4 class="texto-dark-minus-1">Prestadores de serviço:</h4>
    <p class="text-justify">
      Para que possamos disponibilizar nossos produtos e prestar os nossos
      serviços com qualidade, contamos com a colaboração de diversos prestadores
      de serviço, que tratam os dados pessoais coletados em nosso nome e de
      acordo com nossas instruções. Esses prestadores de serviço atuam
      principalmente para auxiliar na guarda de informações, intermediação de
      suporte técnico, gestão de campanhas de marketing, enriquecimento de base
      de dados e armazenamento em nuvem.
    </p>
    <h4 class="texto-dark-minus-1">
      Autoridades judiciais, policiais ou governamentais:
    </h4>
    <p class="text-justify">
      Nós devemos fornecer dados pessoais de Clientes e/ou Usuários, em
      atendimento à ordem judicial, solicitações de autoridades administrativas,
      obrigação legal ou regulatória, bem como para agir de forma colaborativa
      com autoridades governamentais, em geral em investigações envolvendo atos
      ilícitos.
    </p>
    <h4 class="texto-dark-minus-1">Potenciais compradores:</h4>
    <p class="text-justify">
      Podemos compartilhar seus dados pessoais com outras empresas as quais a
      G3soft conclua uma negociação de venda ou transferência de parte ou da
      totalidade da atividade comercial, negócio ou operação da G3soft. Caso a
      venda ou transferência não sejam consumadas, solicitaremos ao potencial
      comprador que não faça uso e não divulgue seus dados pessoais de qualquer
      maneira ou forma, apagando-os por completo.
    </p>
    <h3 class="texto-dark-minus-1">
      Armazenamento e segurança dos dados pessoais
    </h3>
    <p class="text-justify">
      Nós armazenamos seus dados de forma segura em data centers localizados no
      Brasil, bem como no exterior. Neste caso de armazenamento no exterior, são
      adotadas todas as medidas legais aplicáveis, em conformidade com a Lei
      Geral de Proteção de Dados e suas futuras regulamentações, garantindo a
      proteção e privacidade dos seus dados pessoais.
    </p>
    <p class="text-justify">
      Nós adotamos as melhores técnicas para proteger os dados pessoais
      coletados de acessos não autorizados, destruição, perda, alteração,
      comunicação ou qualquer forma de tratamento inadequado ou ilícito,
      inclusive mecanismos de criptografia. Ressaltamos, contudo, que nenhuma
      plataforma é completamente segura. Se você tiver qualquer preocupação ou
      suspeita de que os seus dados estejam em risco, por favor, entre em
      contato conosco por meio dos nossos canais de atendimento, disponíveis ao
      final deste aviso, que ficaremos felizes em te auxiliar.
    </p>
    <p class="text-justify">
      Seus dados pessoais serão mantidos durante todo o período que for um
      cliente ativo da G3soft. Após esse período, podemos armazenar os seus
      dados pessoais por um período adicional para fins de auditoria, para
      possibilitar o cumprimento de obrigações legais ou regulatórias. Faremos a
      retenção dos seus dados pelo prazo necessário, respeitando os prazos
      estabelecidos na legislação aplicável.
    </p>
    <h3 class="texto-dark-minus-1">Cookies e tecnologias de monitoramento</h3>
    <p class="texto-dark-minus-1">
      Veja abaixo algumas das tecnologias que poderão estar presentes nas
      plataformas da G3soft:
    </p>
    <h4 class="texto-dark-minus-1">Cookies:</h4>
    <p class="texto-dark-minus-1">
      Cookie é um pequeno arquivo adicionado ao dispositivo do Usuário para
      fornecer uma experiência personalizada de acesso à plataforma. Os cookies
      ajudam a analisar o tráfego de internet e nos permite saber quando o
      Usuário visitou um site específico. Um cookie não dá acesso a um
      computador ou revela informações além dos dados que o usuário escolhe
      compartilhar conosco
    </p>
    <h4 class="texto-dark-minus-1">Pixels:</h4>
    <p class="texto-dark-minus-1">
      Pixels são partes do código JavaScript instalados em nossas aplicações,
      websites ou no corpo de um e-mail, com a finalidade de rastrear coletar
      informações sobre as atividades dos usuários, permitindo a identificação
      dos seus padrões de acesso, navegação, interesse e compras de produtos, e
      utilizados para melhorar o direcionamento de conteúdo.
    </p>
    <h4 class="texto-dark-minus-1">Web beacon:</h4>
    <p class="texto-dark-minus-1">
      Web beacon é uma técnica que permite mapear quem está visitando uma
      determinada página da web, identificando o comportamento do usuário com
      diferentes sites ou servidores da web.
    </p>
    <h4 class="texto-dark-minus-1">Ferramentas de analytics:</h4>
    <p class="texto-dark-minus-1">
      Essas ferramentas podem coletar informações sobre a forma como os Usuários
      visitam nossos sites, o eGestor, ou outros aplicativos do Grupo G3soft,
      quais páginas eles visitam e quando visitam tais páginas, outros sites que
      foram visitados antes, entre outras.
    </p>
    <h3 class="texto-dark-minus-1">
      Transferência internacional de dados pessoais
    </h3>
    <p class="texto-dark-minus-1">
      Alguns de seus dados pessoais poderão ser transferidos para outros países,
      por exemplo, quando utilizarmos serviços computacionais em nuvem para
      processamento ou armazenamento de dados, localizados fora do Brasil. Mas
      não se preocupe, a G3soft observa também nestas situações todos os
      requisitos estabelecidos na legislação vigente, e adota as melhores
      práticas de mercado a fim de garantir a proteção e privacidade dos seus
      dados pessoais.
    </p>
    <h3 class="texto-dark-minus-1">
      Seus direitos como titular dos dados pessoais
    </h3>
    <p class="texto-dark-minus-1">
      A transparência sobre o tratamento dos seus dados pessoais é prioridade
      para a G3soft. Além das informações disponibilizadas neste Aviso de
      Política de Privacidade, você pode também exercer os direitos previstos na
      Lei Geral de Proteção de Dados, entre eles:
    </p>
    <ul>
      <li>Confirmação da existência de tratamento de dados pessoais;</li>
      <li>Acesso aos dados pessoais;</li>
      <li>Revogação do consentimento;</li>
      <li>
        Correção de dados pessoais incompletos, inexatos ou desatualizados;
      </li>
      <li>
        Eliminação dos dados pessoais tratados com consentimento ou
        desnecessários, excessivos ou quando entender que algum ponto da LGPD
        não foi atendido;
      </li>
      <li>
        Informação sobre as hipóteses e com quais empresas, parceiros e outras
        instituições podemos compartilhar, ou receber dados pessoais referentes
        a você;
      </li>
      <li>
        Informação sobre a possibilidade de não fornecer consentimento e sobre
        as consequências da negativa, quando aplicável;
      </li>
      <li>
        Todas as solicitações serão tratadas de forma gratuita, e serão
        submetidas a uma prévia avaliação da sua identidade e da viabilidade do
        atendimento, a fim de cumprir com eventuais obrigações que impeçam o
        completo atendimento das requisições dos titulares de direito.
      </li>
    </ul>
    <h3 class="texto-dark-minus-1">
      Retenção e exclusão dos seus dados pessoais
    </h3>
    <p class="texto-dark-minus-1">
      Nós trataremos seus dados pessoais com elevados níveis de proteção e
      garantindo sua privacidade, durante todo o período que você for nosso
      cliente, navegando em nossas plataformas e utilizando nossos serviços, ou
      potencial cliente com quem dividimos nossas ofertas de produtos e
      serviços.
    </p>
    <p class="texto-dark-minus-1">
      Quando aplicável, e mesmo se você optar por excluir seus dados pessoais da
      nossa base de cadastro, a G3soft poderá reter alguns ou todos os seus
      dados pessoais por períodos adicionais para cumprimento de obrigações
      legais ou regulatórias, para o exercício regular de direitos da G3soft,
      eventuais ações judiciais, fins de auditoria de diversas naturezas, ou
      outros prazos definidos e fundamentados por bases legais que justifiquem a
      retenção destes dados.
    </p>
    <h3 class="texto-dark-minus-1">Alterações da política de privacidade</h3>
    <p class="texto-dark-minus-1">
      Nossa Política de Privacidade está em constante aprimoramento e nossas
      práticas de tratamento de dados pessoais poderão sofrer alterações com a
      inclusão de novas funcionalidades e serviços. Assim, esse Aviso de
      Política de Privacidade pode ser alterado.
    </p>
    <p class="texto-dark-minus-1">
      Valorizamos a transparência no modo em que tratamos seus dados pessoais.
      Toda vez que alguma condição relevante de nossa Política de Privacidade
      for modificada, essas alterações serão válidas, eficazes e vinculantes
      após a nova versão ser publicada em nosso site. Estas alterações serão
      comunicadas por meio de um dos canais disponibilizados por você durante o
      seu cadastro (e-mail, SMS, WhatsApp e outros).
    </p>
    <h3 class="texto-dark-minus-1">Fale conosco</h3>
    <p class="texto-dark-minus-1">
      Sempre que você tiver alguma dúvida sobre este Aviso de nossa Política de
      Privacidade, mesmo após sua leitura ou precisar interagir conosco sobre
      assuntos envolvendo os seus dados pessoais, poderá fazê-lo por meio de:
    </p>
    <ol>
      <li>
        Nossos canais de atendimento disponíveis em
        <a class="link-texto" href="https://www.g3soft.com.br"
          >https://www.g3soft.com.br</a
        >, solicitando as opções sobre a privacidade dos seus dados pessoais;
      </li>
      <li>
        Pelo e-mail do Encarregado de Dados:
        <a class="link-texto" href="mailto:dpo@G3soft.com.br"
          >dpo@G3soft.com.br.</a
        >
      </li>
    </ol>
    <p class="texto-dark-minus-1">
      Recomendamos que verifiquem a versão atualizada deste Aviso de Política de
      Privacidade sempre que navegarem em qualquer de nossas plataformas.
      Estamos sempre à disposição para esclarecer suas dúvidas e colocar você no
      controle dos seus dados pessoais.
    </p>
    <p class="texto-dark-minus-1">Conte com a gente para mantê-lo informado!</p>
    <h3 class="texto-dark-minus-1">Atualização: 11 de outubro de 2021.</h3>
    <p class="texto-dark-minus-1">G3soft Soluções Tecnológicas LTDA.</p>
  </div>
</div>
