<div class="home-page">
  <div class="home">
    <div class="banner" *ngIf="!isMobile">
      <img src="assets/imagens/banners/20240418/1.png" class="banner-img" />
      <div
        class="banner-content text-left flex flex-column align-items-between"
      >
        <h1 class="mb-6">GESTÃO EMPRESARIAL DESCOMPLICADA</h1>
        <span class="mb-6">
          Ponto de vendas, movimentação de caixa, gestão de estoque e controle
          financeiro. Sua empresa inteira em um único lugar.
        </span>
        <div class="banner-content-buttons flex justify-content-around">
          <p-button
            label="Agende uma demonstração"
            class="secondary-button mt-5"
            (onClick)="abrirDialogFaleConosco()"
          ></p-button>
          <p-button
            label="Quero conhecer mais"
            (onClick)="divSolucoes.scrollIntoView()"
            class="primary-button-outline mt-5"
          ></p-button>
        </div>
      </div>
    </div>
    <div class="banner" *ngIf="isMobile">
      <img src="assets/imagens/banners/20240418/2.png" class="banner-img" />
      <div class="banner-content mobile text-justify">
        <h1 class="mb-5">GESTÃO EMPRESARIAL DESCOMPLICADA</h1>
        <span>
          Ponto de vendas, movimentação de caixa, gestão de estoque e controle
          financeiro. Sua empresa inteira em um único lugar.
        </span>
        <div class="banner-content-buttons mt-8">
          <p-button
            label="Agende uma demonstração"
            class="secondary-button"
            (onClick)="abrirDialogFaleConosco()"
            styleClass="w-full mb-5"
          ></p-button>
          <p-button
            label="Quero conhecer mais"
            (onClick)="divSolucoes.scrollIntoView()"
            class="primary-button-outline"
            styleClass="w-full"
          ></p-button>
        </div>
      </div>
    </div>
    <!-- <div class="carousel">
      <div class="carousel-content">
        <div class="carousel-items">
          <div class="carousel-item active fadeIn">
            <div class="carousel-item-images">
              <img
                src="assets/imagens/banners/20240418/1.png"
                style="
                  background-size: cover;
                  background-position: center;
                  height: auto;
                  width: 100%;
                "
              />
              <img
                src="assets/imagens/banners/20240418/2.png"
                class="carousel-item-image-2"
              />
            </div>
          </div>
          <ng-container *ngFor="let slide of slides">
            <div
              class="carousel-item"
              [ngClass]="{ 'active fadeIn': verificarSlideAtivo(slide.index) }"
            >
              <a [routerLink]="slide.routerLink" target="_blank">
                <img
                  [src]="slide.imgSrc"
                  style="
                    background-size: cover;
                    background-position: center;
                    height: auto;
                    width: 100%;
                  "
                />
              </a>
            </div>
          </ng-container>
        </div>
        <div
          class="arrow left"
          (click)="voltarSlide()"
          *ngIf="slides.length > 1"
        >
          <i class="pi pi-angle-left"></i>
        </div>
        <div
          class="arrow right"
          (click)="proximoSlide()"
          *ngIf="slides.length > 1"
        >
          <i class="pi pi-angle-right"></i>
        </div>
      </div>
    </div> -->
  </div>
  <div class="segmentos container animacao">
    <div class="titulo">
      <span>ATENDEMOS VÁRIOS TIPOS DE SEGMENTOS</span>
    </div>
    <span class="subtitulo mt-2">
      Adaptamos nosso produto para as necessidade de cada negócio.
    </span>
    <app-infinite-carousel-segments></app-infinite-carousel-segments>
  </div>
  <div class="animacao" style="margin-left: -2px">
    <img src="assets/imagens/banners/20241002/anos_mercado.png" width="100%" />
  </div>
  <div
    #divSolucoes
    class="solucoes container animacao"
    [ngClass]="{ animacao: mostrarServicos }"
  >
    <div class="titulo">
      <span>As melhores soluções em um só lugar.</span><br />
      <span>Você contrata somente as soluções que o seu negócio precisa.</span>
    </div>

    <div class="lista-solucoes">
      <ng-container *ngFor="let solucao of solucoes">
        <div class="card-solucao">
          <img [src]="solucao.imgSrc" />
          <h1 class="text-dark-minus-2">{{ solucao.title }}</h1>
          <span>{{ solucao.text }}</span>
          <p-button
            label="Saiba mais"
            [routerLink]="solucao.routerLink"
            class="on-light-button-outline mt-5"
          ></p-button>
        </div>
      </ng-container>
    </div>
  </div>
  <div
    class="servicos container animacao"
    [ngClass]="{ animacao: mostrarServicos }"
  >
    <div class="lista-servicos">
      <div class="grid servico justify-content-center">
        <div class="col-12 xl:col-6 servico-imagem">
          <img src="assets/imagens/home/1.png" />
        </div>
        <div class="col-12 xl:col-6 servico-detalhes">
          <div class="servico-titulo text-left">
            FACILIDADE É A CHAVE DO SEU
            <span class="texto-primary">SUCESSO</span>
          </div>
          <span class="servico-texto">
            Emitir notas fiscais nunca foi tão simples e fácil. Economize tempo
            e fature mais emitindo notas fiscais em poucos minutos com a G3soft.
          </span>
          <br />
          <br />
        </div>
      </div>
      <div class="grid servico justify-content-center flex-row-reverse">
        <div class="col-12 xl:col-6 servico-imagem">
          <img src="assets/imagens/home/2.png" />
        </div>
        <div class="col-12 xl:col-6 servico-detalhes">
          <div class="servico-titulo text-right align-self-end">
            <span class="texto-primary">CONTROLE TOTAL</span> DE TODAS AS
            OPERAÇÕES
          </div>
          <span class="servico-texto text-right">
            Controle de estoque que facilita a gestão da sua empresa, Você não
            precisa usar mil planilhas para saber quais produtos estão sobrando
            ou faltando. Controle as entradas e saídas de produtos em tempo
            real.
          </span>
        </div>
      </div>
      <div class="grid servico justify-content-center">
        <div class="col-12 xl:col-6 servico-imagem">
          <img src="assets/imagens/home/3.png" />
        </div>
        <div class="col-12 xl:col-6 servico-detalhes">
          <div class="servico-titulo text-left">
            TUDO EM UM SÓ LUGAR
            <span class="texto-primary">AO SEU ALCANCE</span>
          </div>
          <span class="servico-texto">
            Tenha todos os dados da sua operação na palma da mão. Com G3Advisor,
            assim fica mais fácil gerenciar seu negócio e acompanhar suas vendas
            de qualquer lugar e a qualquer hora.
          </span>
        </div>
      </div>
    </div>
  </div>
  <app-fale-conosco></app-fale-conosco>
</div>

<app-fale-conosco-dialog
  [(showDialog)]="mostrarDialogFaleConosco"
></app-fale-conosco-dialog>
