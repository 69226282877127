import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {CentralAjudaComponent} from './presentation/central-ajuda/central-ajuda.component';
import {DesenvolvedoresComponent} from './presentation/desenvolvedores/desenvolvedores.component';
import {FabricaSoftwareComponent} from './presentation/fabrica-software/fabrica-software.component';
import {HomeComponent} from './presentation/home/home.component';
import {ParceriasComponent} from './presentation/parcerias/parcerias.component';
import {PoliticaCookiesComponent} from './presentation/politica-cookies/politica-cookies.component';
import {PoliticaPrivacidadeComponent} from './presentation/politica-privacidade/politica-privacidade.component';
import {TermosUsoComponent} from './presentation/termos-uso/termos-uso.component';
import {TratamentoDadosComponent} from './presentation/tratamento-dados/tratamento-dados.component';

const routes: Routes = [
  // {
  //   path: '',
  //   component: LandingComponent,
  // },
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'segmentos',
    loadChildren: () => import('./presentation/segmentos/segmentos.module')
                            .then(m => m.SegmentosModule),
  },
  {
    path: 'funcionalidades',
    loadChildren: () =>
        import('./presentation/funcionalidades/funcionalidades.module')
            .then(m => m.FuncionalidadesModule),
  },
  {
    path: 'solucoes',
    loadChildren: () => import('./presentation/solucoes/solucoes.module')
                            .then(m => m.SolucoesModule),
  },
  {
    path: 'parcerias',
    component: ParceriasComponent,
  },
  {
    path: 'politica-cookies',
    component: PoliticaCookiesComponent,
  },
  {
    path: 'central-de-ajuda',
    component: CentralAjudaComponent,
  },
  {
    path: 'desenvolvedores',
    component: DesenvolvedoresComponent,
  },
  {
    path: 'termos-de-uso',
    component: TermosUsoComponent,
  },
  {
    path: 'politica-privacidade',
    component: PoliticaPrivacidadeComponent,
  },
  {
    path: 'tratamento-de-dados',
    component: TratamentoDadosComponent,
  },
  {
    path: 'fabrica-software',
    component: FabricaSoftwareComponent,
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
