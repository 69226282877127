import {AgmCoreModule} from '@agm/core';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AngularLineawesomeModule} from 'angular-line-awesome';
import {NgxMaskDirective, NgxMaskPipe, provideNgxMask} from 'ngx-mask';
import {AccordionModule} from 'primeng/accordion';
import {MessageService} from 'primeng/api';
import {ButtonModule} from 'primeng/button';
import {DialogModule} from 'primeng/dialog';
import {DividerModule} from 'primeng/divider';
import {InputTextModule} from 'primeng/inputtext';
import {MenuModule} from 'primeng/menu';
import {MenubarModule} from 'primeng/menubar';
import {MessagesModule} from 'primeng/messages';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {PanelMenuModule} from 'primeng/panelmenu';
import {ScrollTopModule} from 'primeng/scrolltop';
import {SidebarModule} from 'primeng/sidebar';
import {ToastModule} from 'primeng/toast';
import {TooltipModule} from 'primeng/tooltip';

import {ContatoHeaderComponent} from './components/contato-header/contato-header.component';
import {CookiesAcceptComponent} from './components/cookies-accept/cookies-accept.component';
import {FaleConoscoDialogComponent} from './components/fale-conosco-dialog/fale-conosco-dialog.component';
import {FaleConoscoSectionComponent as FaleConoscoComponent} from './components/fale-conosco/fale-conosco.component';
import {FooterComponent} from './components/footer/footer.component';
import {HeaderComponent} from './components/header/header.component';
import {InfiniteCarouselSegmentsComponent} from './components/infinite-carousel-segments/infinite-carousel-segments.component';
import {InfiniteCarouselTecnologiasComponent} from './components/infinite-carousel-tecnologias/infinite-carousel-tecnologias.component';
import {MiniHeaderComponent} from './components/mini-header/mini-header.component';
import {SolucaoModulosCardComponent} from './components/solucao-modulos-card/solucao-modulos-card.component';
import {WhatsFloatButtonComponent} from './components/whats-float-button/whats-float-button.component';

@NgModule({
  imports: [
    CommonModule,
    AgmCoreModule.forRoot({apiKey: 'AIzaSyBiG6nVHLmHi3EbnzB5Yqi7Rb0L4mP44jU'}),
    SidebarModule,
    MenuModule,
    AccordionModule,
    InputTextModule,
    PanelMenuModule,
    ScrollTopModule,
    MenubarModule,
    DividerModule,
    TooltipModule,
    ButtonModule,
    DialogModule,
    OverlayPanelModule,
    FormsModule,
    ReactiveFormsModule,
    AngularLineawesomeModule,
    MessagesModule,
    ToastModule,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  declarations: [
    SolucaoModulosCardComponent,
    HeaderComponent,
    InfiniteCarouselTecnologiasComponent,
    InfiniteCarouselSegmentsComponent,
    FooterComponent,
    CookiesAcceptComponent,
    MiniHeaderComponent,
    ContatoHeaderComponent,
    WhatsFloatButtonComponent,
    FaleConoscoDialogComponent,
    FaleConoscoComponent,
  ],
  exports: [
    CommonModule,
    AgmCoreModule,
    SidebarModule,
    MenuModule,
    AccordionModule,
    InputTextModule,
    PanelMenuModule,
    ScrollTopModule,
    MenubarModule,
    DividerModule,
    TooltipModule,
    ButtonModule,
    DialogModule,
    OverlayPanelModule,
    FormsModule,
    ReactiveFormsModule,
    AngularLineawesomeModule,
    MessagesModule,
    NgxMaskDirective,
    NgxMaskPipe,
    ToastModule,

    SolucaoModulosCardComponent,
    HeaderComponent,
    InfiniteCarouselTecnologiasComponent,
    InfiniteCarouselSegmentsComponent,
    FooterComponent,
    CookiesAcceptComponent,
    MiniHeaderComponent,
    ContatoHeaderComponent,
    WhatsFloatButtonComponent,
    FaleConoscoDialogComponent,
    FaleConoscoComponent,
  ],
  providers: [
    MessageService,
    provideNgxMask(),
  ],
})
export class SharedModule {
}
