import {Injectable} from '@angular/core';

import {EmailService} from '../data/services/email.service';

@Injectable({providedIn: 'root'})
export class AppFacade {
  contatoEmail = 'contato@g3soft.com.br';

  constructor(private emailService: EmailService) {}

  enviarEmailParaContato(
      nome: string,
      email: string,
      documento: string,
      telefone: string,
      assunto: string,
  ) {
    return this.emailService.enviarEmailParaContato(
        nome,
        email,
        documento,
        telefone,
        this.contatoEmail,
        assunto,
    );
  }
}
