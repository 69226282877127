import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CookieState {
  get cookieAceito() {
    return JSON.parse(
      localStorage.getItem('cookies_accepted') ?? 'false'
    ) as boolean;
  }
}
