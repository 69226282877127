import {Component, EventEmitter, OnInit, Output} from '@angular/core';

import {AppMenuItem} from '../../interfaces/app-menu-item.interface';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Output() toggleSidebar = new EventEmitter();

  items: AppMenuItem[] = [];
  solucoes: AppMenuItem[] = [];
  funcionalidades: AppMenuItem[] = [];
  segmentos: AppMenuItem[] = [];

  ngOnInit() {
    this.solucoes = [
      {
        routerLink: '/solucoes/g3control',
        imgSrc: 'assets/imagens/solucoes/g3control/g3-control-300x40.png',
        text:
            `Controle completo para médios e grandes varejos. Integração com PDV, AFV, multilojas, Compras, estoque, financeiro, fiscal e entre outros recursos.`,
        separator: true,
      },
      {
        routerLink: '/solucoes/g3erp',
        imgSrc: 'assets/imagens/solucoes/g3erp/g3-erp-300x75.png',
        text:
            `Acompanhe todas as operações da sua empresa em tempo real, sua gestão completa 100% na nuvem e segura, integrado com as soluções, G3Small, G3Control e G3Pedidos.`,
        separator: true,
      },
      {
        routerLink: '/solucoes/g3food',
        imgSrc: 'assets/imagens/solucoes/g3food/g3-food-300x56.png',
        text:
            `Uma solução Food Service completa que agiliza sua operação de atendimento e entrega. Rapidez e praticidade ao fechar e produzir seus pedidos.`,
        separator: true,
      },
      {
        routerLink: '/solucoes/g3pedidos',
        imgSrc: 'assets/imagens/solucoes/g3pedidos/g3-pedidos-300x50.png',
        text:
            `Aumente a produtividade da sua equipe de vendas! Desenvolvido para facilitar o dia-a-dia de vendedores externos utilizando Smartphones e/ou tablets para registrar pedidos.`,
        separator: true,
      },
      {
        routerLink: '/solucoes/g3small',
        imgSrc: 'assets/imagens/solucoes/g3small/g3-small-300x55.png',
        text:
            `Indicado para o pequeno negócio, ajuda na gestão comercial e financeira da empresa. Simples e de fácil usabilidade, traz recursos como: Multilojas, ponto de venda, pedidos, cadastro de clientes e produtos, estoque, documentos fiscais e diversos relatórios.`,
      },
    ];
    this.funcionalidades = [
      {
        title: 'FATURAMENTO',
        items: [
          {title: 'Cadastro de produtos'},
          {title: 'Cadastro de clientes'},
          {title: 'Frente de caixa - PDV'},
          {title: 'Pedido de venda'},
        ],
        separator: true,
      },
      {
        title: 'CONTROLE DE ESTOQUE',
        items: [
          {title: 'Ordem de produção'},
          {title: 'Ficha técnica'},
          {title: 'Entrada de inventário'},
          {title: 'Conferência de estoque'},
          {title: 'Cor e Tamanho'},
        ],
        separator: true,
      },
      {
        title: 'GESTÃO FINANCEIRA',
        items: [
          {title: 'Fluxo de caixa'},
          {title: 'Controle de comissões'},
          {title: 'Documento financeiro'},
          {title: 'Boletos bancários'},
          {title: 'DRE'},
        ],
        separator: true,
      },
      {
        title: 'DOCUMENTOS FISCAIS',
        items: [
          {title: 'Nota fiscal'},
          {title: 'Cupom fiscal e NFC-E'},
          {title: 'Manifesto de carga'},
          {title: 'SAT'},
        ],
        separator: true,
      },
      {
        title: 'LOJA VIRTUAL',
        items: [
          {title: 'Catálogo digital'},
          {title: 'Integração e-commerce'},
          {title: 'Maquininha de cartão'},
        ],
      },
    ];
    this.segmentos = [
      {
        title: 'Cafés, Bares e Restaurantes',
        routerLink: '/segmentos/bares-restaurantes',
        icon: 'assets/imagens/segmentos/icones/1.png',
      },
      {
        title: 'Mercados e Empórios',
        routerLink: '/segmentos/mercados-emporios',
        icon: 'assets/imagens/segmentos/icones/2.png',
      },
      {
        title: 'Moda, Roupas e Acessórios',
        routerLink: '/segmentos/roupas-acessorios',
        icon: 'assets/imagens/segmentos/icones/3.png',
      },
      {
        title: 'Pizzarias e Lanchonetes',
        routerLink: '/segmentos/pizzarias-lanchonetes',
        icon: 'assets/imagens/segmentos/icones/9.png',
      },
      {
        title: 'Pet Shop',
        routerLink: '/segmentos/petshop',
        icon: 'assets/imagens/segmentos/icones/14.png',
      },
      {
        title: 'Materiais de Construção',
        routerLink: '/segmentos/material-construcao',
        icon: 'assets/imagens/segmentos/icones/15.png',
      },
      {
        title: 'Livrarias e Papelarias',
        routerLink: '/segmentos/livrarias-papelarias',
        icon: 'assets/imagens/segmentos/icones/4.png',
      },
      {
        title: 'Perfumaria e Beleza',
        routerLink: '/segmentos/perfumaria-beleza',
        icon: 'assets/imagens/segmentos/icones/6.png',
      },
      {
        title: 'Padarias e Conveniências',
        routerLink: '/segmentos/padarias-conveniencias',
        icon: 'assets/imagens/segmentos/icones/7.png',
      },
      {
        title: 'Móveis e Decorações',
        routerLink: '/segmentos/moveis-decoracoes',
        icon: 'assets/imagens/segmentos/icones/13.png',
      },
      {
        title: 'Sorveterias e Açaiterias',
        routerLink: '/segmentos/sorveterias-acaiterias',
        icon: 'assets/imagens/segmentos/icones/18.png',
      },
      {
        title: 'Floriculturas',
        routerLink: '/segmentos/floriculturas',
        icon: 'assets/imagens/segmentos/icones/19.png',
      },
      {
        title: 'Periféricos e Informática',
        routerLink: '/segmentos/perifericos-informatica',
        icon: 'assets/imagens/segmentos/icones/20.png',
      },
      {
        title: 'Bicicletarias e Oficinas em Geral',
        routerLink: '/segmentos/bicicletarias-oficinas',
        icon: 'assets/imagens/segmentos/icones/10.png',
      },
      {
        title: 'Bazar, Presentes e Lembranças',
        routerLink: '/segmentos/presentes-lembrancas',
        icon: 'assets/imagens/segmentos/icones/11.png',
      },
      {
        title: 'Açougues e Boutiques de Carne',
        routerLink: '/segmentos/acougues',
        icon: 'assets/imagens/segmentos/icones/17.png',
      },
      {
        title: 'Depósito de Bebidas, Água e Gás',
        routerLink: '/segmentos/bebidas-agua-gas',
        icon: 'assets/imagens/segmentos/icones/12.png',
      },
      {
        title: 'Utilidades para o Lar',
        routerLink: '/segmentos/utilidades-lar',
        icon: 'assets/imagens/segmentos/icones/8.png',
      },
      {
        title: 'Tabacarias',
        routerLink: '/segmentos/tabacarias',
        icon: 'assets/imagens/segmentos/icones/16.png',
      },
      {
        title: 'Eletrônicos',
        routerLink: '/segmentos/eletronicos',
        icon: 'assets/imagens/segmentos/icones/20.png',
      },
    ];
    this.items = [
      {
        label: 'FUNCIONALIDADES',
        items: [],
      },
      {
        label: 'SEGMENTOS',
        items: [],
      },
      {
        label: 'PARCERIAS',
        routerLink: '/parcerias',
      },
    ];
  }
  aoClicarMenu() {
    this.toggleSidebar.emit();
  }
}
