import { Component, OnInit } from '@angular/core';
import { CookieFacade } from '@app/domain/facades/cookie.facade';

@Component({
  selector: 'app-cookies-accept',
  templateUrl: './cookies-accept.component.html',
  styleUrls: ['./cookies-accept.component.scss'],
})
export class CookiesAcceptComponent implements OnInit {
  constructor(private cookiesFacade: CookieFacade) {}
  get cookiesAceito() {
    return this.cookiesFacade.cookiesAceito;
  }
  ngOnInit() {}
  aceitarCookies() {
    this.cookiesFacade.setarCookie();
  }
}
