import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({ providedIn: 'root' })
export class DeviceDetectorFacade {
  constructor(private deviceDetector: DeviceDetectorService) {}
  get isDesktop() {
    return this.deviceDetector.isDesktop();
  }
  get isMobile() {
    return this.deviceDetector.isMobile();
  }
  get isTablet() {
    return this.deviceDetector.isTablet();
  }
  get deviceInfo() {
    return this.deviceDetector.getDeviceInfo();
  }
}
