import {Component, HostListener, OnInit} from '@angular/core';
import {AppFacade} from '@app/domain/facades/app.facade';
import {DeviceDetectorFacade} from '@app/domain/facades/device-detector.facate';
import {AppMenuItem} from '@app/shared/interfaces/app-menu-item.interface';
import {Subscription, timer} from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  slideAtual = 1;
  mostrarSobreNos = false;
  mostrarServicos = false;
  timerSlidesSub!: Subscription;

  slides: AppMenuItem[] = [
    // {
    //   index: 1,
    //   imgSrc: 'assets/imagens/banners/20240418/1.png',
    // },
    // {
    //   index: 2,
    //   imgSrc: 'assets/imagens/banners/20230918/8.png',
    // },
    // {
    //   index: 3,
    //   imgSrc: 'assets/imagens/banners/20230918/9.png',
    //   routerLink: '/solucoes/g3small',
    // },
    // {
    //   index: 4,
    //   imgSrc: 'assets/imagens/banners/20230918/10.png',
    //   routerLink: '/solucoes/g3control',
    // },
    // {
    //   index: 5,
    //   imgSrc: 'assets/imagens/banners/20230918/11.png',
    //   routerLink: '/solucoes/g3pedidos',
    // },
    // {
    //   index: 6,
    //   imgSrc: 'assets/imagens/banners/20230918/12.png',
    //   routerLink: '/solucoes/g3food',
    // },
  ]
  solucoes: AppMenuItem[] = [
    {
      index: 1,
      title: 'G3CONTROL',
      imgSrc: 'assets/imagens/solucoes/g3control/control.png',
      text:
          'Controle completo para médios e grandes varejos. Integração com PDV, AFV, multilojas, compras, estoque, financeiro, fiscal entre outros recursos.',
      routerLink: '/solucoes/g3control',
    },
    {
      index: 2,
      title: 'G3ERP',
      imgSrc: 'assets/imagens/solucoes/g3erp/erp.png',
      text:
          'Acompanhe todas as operações da sua empresa em tempo real, sua gestão completa 100% na nuvem e segura, integrado com as soluções, G3Small, G3Control e G3Pedidos.',
      routerLink: '/solucoes/g3erp',
    },
    {
      index: 3,
      title: 'G3FOOD',
      imgSrc: 'assets/imagens/solucoes/g3food/food.png',
      text:
          'Uma solução Food Service completa que agiliza sua operação de atendimento e entrega. Rapidez e praticidade ao fechar e produzir seus pedidos.',
      routerLink: '/solucoes/g3food',
    },
    {
      index: 4,
      title: 'G3PEDIDOS',
      imgSrc: 'assets/imagens/solucoes/g3pedidos/pedidos.png',
      text:
          'Aumente a produtividade da sua equipe de vendas! Desenvolvido para facilitar o dia a dia de vendedores externos utilizando smartphones e/ou tablets para registrar pedidos.',
      routerLink: '/solucoes/g3pedidos',
    },
    {
      index: 5,
      title: 'G3SMALL',
      imgSrc: 'assets/imagens/solucoes/g3small/small.png',
      text:
          'Indicado para o pequeno negócio, ajuda na gestão comercial e financeira da empresa. Simples e fácil usar, traz recursos como: multilojas, PDV, pedidos, cadastro de clientes e produtos, estoque, documentos fiscais e diversos relatórios.',
      routerLink: '/solucoes/g3small',
    },
  ];
  mostrarDialogFaleConosco: boolean;

  get isMobile() {
    return this.deviceDetectorFacade.isMobile;
  }

  get isTablet() {
    return this.deviceDetectorFacade.isTablet;
  }

  get isDesktop() {
    return this.deviceDetectorFacade.isDesktop;
  }

  constructor(
      private deviceDetectorFacade: DeviceDetectorFacade,
  ) {}

  ngOnInit() {
    this.iniciarContadorSlides();
  }
  @HostListener('window:scroll', ['$event'])
  onScroll(event: any) {
    if (event.currentTarget.scrollY > 200) {
      this.mostrarServicos = true;
    }
    if (event.currentTarget.scrollY > 1400) {
      this.mostrarSobreNos = true;
    }
  }
  iniciarContadorSlides() {
    if (this.timerSlidesSub) {
      this.timerSlidesSub.unsubscribe();
    }
    const timerSlides = timer(8000, 8000);
    this.timerSlidesSub = timerSlides.subscribe(() => this.proximoSlide());
  }
  verificarSlideAtivo(slide: number) {
    return slide === this.slideAtual;
  }
  voltarSlide() {
    this.iniciarContadorSlides();
    if (this.slideAtual === 1) {
      this.slideAtual = this.slides.length;
    } else {
      this.slideAtual--;
    }
  }
  proximoSlide() {
    this.iniciarContadorSlides();
    if (this.slideAtual === this.slides.length) {
      this.slideAtual = 1;
    } else {
      this.slideAtual++;
    }
  }
  abrirDialogFaleConosco() {
    this.mostrarDialogFaleConosco = true;
  }
}
