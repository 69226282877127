<app-header (toggleSidebar)="toggleSidebar()"></app-header>
<router-outlet></router-outlet>
<app-whats-float-button></app-whats-float-button>
<app-footer></app-footer>
<app-cookies-accept></app-cookies-accept>
<p-scrollTop></p-scrollTop>
<p-sidebar
  position="right"
  [(visible)]="mostrarSidebar"
>
  <p-menu [model]="items"></p-menu>
</p-sidebar>
