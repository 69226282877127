import {HashLocationStrategy, LocationStrategy, PathLocationStrategy,} from '@angular/common';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {CoreModule} from './core/core.module';
import {HomeComponent} from './presentation/home/home.component';
import {LandingComponent} from './presentation/landing/landing.component';
import {ParceriasComponent} from './presentation/parcerias/parcerias.component';
import {PoliticaCookiesComponent} from './presentation/politica-cookies/politica-cookies.component';
import {SharedModule} from './shared/shared.module';

@NgModule({
  imports: [CoreModule, SharedModule],
  declarations: [
    AppComponent,
    HomeComponent,
    LandingComponent,
    PoliticaCookiesComponent,
    ParceriasComponent,
  ],
  providers: [
    [
      Location,
      {provide: LocationStrategy, useClass: PathLocationStrategy},
      {provide: LocationStrategy, useClass: HashLocationStrategy},
    ],
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
