<div class="pagina-detalhes">
  <div class="sub-header container">
    <span class="sub-header-titulo">TERMOS DE USO</span>
  </div>
  <div class="container animacao text-left">
    <!-- #region INTRODUÇÃO AOS TERMOS E CONDIÇÕES DE USO -->
    <h2 class="texto-dark-minus-1 text-center">
      TERMOS E CONDIÇÕES DE USO DO PORTAL G3 E DE LICENÇA DE USO TEMPORÁRIO DE
      PROGRAMAS DE COMPUTADOR
    </h2>
    <p class="text-justify">
      Ao usar o Portal e adquirir licenças de uso temporário de programas de
      computador, você concorda com as presentes condições gerais descritas
      neste documento. Por isso, é indispensável a aceitação dos termos
      descritos a seguir.
    </p>
    <p class="text-justify">
      Este documento de Termos e Condições de Uso e de Licença de Uso Temporário
      de Programas de Computador (“Termos”) regula a relação entre o contratante
      (pessoa física ou jurídica) denominado CONTRATANTE e/ou Usuário e a DUTRA
      E BARROS COMERCIO E SERVICOS DE INFO LTDA. ME., inscrita no CNPJ/MF sob o
      no 11.178.691/0001-34, com sede na Rua Celso Garcia, 31 - Centro, 14300049
      - Batatais - SP, doravante denominada G3, para uso dos produtos e
      serviços.
    </p>
    <!-- #endregion -->

    <!-- #region 1. DO OBJETO -->
    <h3 class="texto-dark-minus-1">1. DO OBJETO</h3>
    <p class="text-justify">
      O presente Termo tem como objetivo regular as condições de acesso ao
      sistema G3 e das licenças de uso de programas de computador
      disponibilizados., de titularidade da G3 ou de terceiros, sem prejuízo de
      eventuais termos adicionais, aplicáveis a alguns Produtos e/ou Serviços,
      que se incorporarão às condições gerais deste Termo.
    </p>
    <p class="text-justify">
      Os Produtos e/ou Serviços disponibilizados dentro do Portal G3 poderão ser
      adquiridos pelo CONTRATANTE e serão regidos por esses Termos. Caso o
      CONTRATANTE realize a aquisição de Produtos e/ou Serviços disponibilizados
      no espaço de market place no Portal G3, poderão ser aplicados termos ou
      exigências adicionais para estes determinados Produtos e/ou Serviços, uma
      vez que prestados diretamente por terceiros. Os termos adicionais, se e
      quando aplicáveis, estarão disponíveis previamente ao CONTRATANTE, no
      momento de aquisição dos Produtos e/ou Serviços, os quais se incorporarão
      a esses Termos, caso o CONTRATANTE utilize-os.
    </p>
    <!-- #endregion -->

    <!-- #region 2. CONDIÇÕES DE ACESSO -->
    <h3 class="texto-dark-minus-1">2. CONDIÇÕES DE ACESSO</h3>
    <p class="text-justify">
      O acesso ao Portal será público, à título gratuito ou oneroso, dependendo
      da modalidade contratada pelo CONTRATANTE, através do endereço
      <a href="www.g3erp.com.br" class="link-texto">www.g3erp.com.br</a>, ou
      outro indicado pela G3, a seu critério
    </p>
    <p class="text-justify">
      Ao conceder acesso ao Portal, ou a qualquer funcionalidade nele disponível
      de forma gratuita ou onerosa, a G3 não transfere a você (“Usuário")
      quaisquer direitos de propriedade industrial e/ou intelectual sobre
      qualquer componente, criação, programa de computador, imagem, desenho, ou
      qualquer outro item disponível no Portal, concedendo apenas e tão somente
      uma licença de uso temporária, não exclusiva, intransferível e revogável,
      a qualquer tempo, a critério da G3.
    </p>
    <p class="text-justify">
      À G3 fica reservado o direito de, a qualquer momento, e a seu exclusivo
      critério, modificar as condições de acesso ao Portal, alterando seu design
      e/ou sua forma de apresentação, incluindo ou suprimindo funcionalidades
      e/ou informações, ou realizando qualquer outra alteração, de qualquer
      ordem, que não trarão impacto na usabilidade regular do Portal pelo
      CONTRATANTE, sem que tal fato resulte em qualquer direito de o Usuário
      reclamar perdas e danos, a qualquer título. Alterações ao Portal que
      possam impactar em sua usabilidade regular serão previamente comunicadas
      ao CONTRATANTE pela G3.
    </p>
    <!-- #endregion -->

    <!-- #region 3. SUA CONTA NO PORTAL -->
    <h3 class="texto-dark-minus-1">3. SUA CONTA NO PORTAL</h3>
    <p class="text-justify">
      Caso o Usuário crie uma conta para acesso ao Portal, deverá fornecer dados
      verdadeiros e precisos, ficando responsável pelo sigilo de seu nome de
      usuário e senha, que são pessoais e intransferíveis.
    </p>
    <p class="text-justify">
      Qualquer atividade a partir da conta do Usuário no Portal, mediante a
      utilização de suas credenciais de acesso (nome de usuário e senha), ainda
      que indevida, será de responsabilidade do Usuário, uma vez que responsável
      por manter o sigilo de seus dados de acesso.
    </p>
    <!-- #endregion -->

    <!-- #region 4. SERVIÇOS E PRODUTOS FORNECIDOS MEDIANTE PAGAMENTO -->
    <h3 class="texto-dark-minus-1">
      4. SERVIÇOS E PRODUTOS FORNECIDOS MEDIANTE PAGAMENTO
    </h3>
    <p class="text-justify">
      Alguns Produtos e/ou Serviços disponíveis dentro do Portal poderão ser
      fornecidos mediante pagamento, o que será informado na página do Portal
      relativa à contratação do Produto e/ou Serviço.
    </p>
    <p class="text-justify">
      Eventuais Produtos fornecidos gratuitamente poderão passar a ser
      fornecidos mediante pagamento, a qualquer momento, a critério da G3.
      Qualquer alteração nas condições de fornecimento dos Produtos será
      previamente comunicada ao CONTRATANTE através do Portal.
    </p>
    <p class="text-justify">
      A G3 poderá disponibilizar, por meio do Portal, Serviços e/ou Produtos de
      terceiros, os quais são de responsabilidade exclusiva desses terceiros,
      sem qualquer responsabilidade, solidária ou subsidiária por parte da G3.
      Os terceiros poderão solicitar que o Usuário aceite termos e condições
      adicionais, que são celebrados entre o Usuário e esses terceiros, sem
      qualquer participação ou anuência da G3.
    </p>
    <p class="text-justify">
      O acesso a Serviços e/ou Produtos fornecidos mediante pagamento será
      liberado ao Usuário em até 24 (vinte e quatro) horas após a contratação e
      pagamento pelos Serviços e/ou Produtos.
    </p>
    <!-- #endregion -->

    <!-- #region 5. LICENÇAS DE USO DE PROGRAMAS DE COMPUTADOR  -->
    <h3 class="texto-dark-minus-1">
      5. LICENÇAS DE USO DE PROGRAMAS DE COMPUTADOR
    </h3>
    <p class="text-justify">
      Os programas de computador fornecidos no Portal ("Produtos"),
      gratuitamente ou mediante pagamento, são licenciados ao Usuário de forma
      não exclusiva, intransferível, e temporária, observadas as seguintes
      condições:
    </p>
    <ol type="A">
      <li>
        Todos os Produtos disponibilizados serão acessíveis on-line, via
        Internet, através do Portal, sujeitos às disposições destas condições
        Gerais.
      </li>
      <li>
        Licenças de uso de Produtos concedidas gratuitamente, ou por período de
        testes gratuitos, terão validade pelo período determinado pelo titular
        dos direitos autorais do programa de computador ("Licenciante"), que
        poderá ser reduzido ou estendido, a qualquer tempo e a critério do
        Licenciante, e independentemente de prévio aviso.
      </li>
      <li>
        Licenças de uso de Produtos concedidas gratuitamente, ou por período de
        testes gratuitos, poderão ter limitações em relação à versão paga, a
        critério do Licenciante, e não contarão com qualquer tipo de suporte ou
        garantia, implícita ou explícita, a qualquer título, inclusive de nível
        de serviço.
      </li>
      <li>
        Licenças de uso de Produtos em fase de pré-lançamento não contarão com
        qualquer tipo de suporte ou garantia, implícita ou explícita, a qualquer
        título, inclusive de nível de serviço.
      </li>
      <li>
        Ao final do período de qualquer licença de uso de Produtos concedida por
        período de testes gratuitos, o Usuário deverá manifestar, expressamente,
        seu eventual interesse na contratação do Produto testado, sob pena de
        ter seu acesso ao Produto interrompido, automaticamente, ao final do
        período de testes, ficando ciente que todos os dados do Usuário,
        inseridos por ele no Produto, durante os testes, serão automaticamente
        apagados.
      </li>
      <li>
        Os Produtos licenciados deverão ser utilizados somente para as operações
        do Usuário, e por prepostos a serviço do Usuário.
      </li>
      <li>
        Somente usuários autorizados pelo Usuário poderão fazer uso dos Produtos
        licenciados, e somente usuários administradores poderão adicionar ou
        excluir usuários autorizados, bem como alterar a contratação dos
        Produtos, com a inclusão/exclusão de novos usuários, módulos, ou
        qualquer outra característica disponível.
      </li>
      <li>
        Todas as licenças de uso comercializadas através do Portal são de
        caráter temporário, válidas pelo período pago pelo Usuário, e o não
        pagamento de qualquer novo período ensejará o cancelamento imediato do
        acesso ao programa de computador licenciado, que somente será
        reestabelecido mediante a quitação do débito.
      </li>
      <li>
        Talvez seja necessário que o Usuário instale um software cliente para
        acessar e usar o Produto licenciado, especialmente se tratando de
        dispositivos móveis. Nessa hipótese, o Usuário deverá observar as
        condições deste Termo também no uso do software cliente, sem prejuízo de
        observar condições específicas de licenciamento do referido software
        cliente.
      </li>
      <li>
        Ao adquirir qualquer licença de uso de Produtos, no Portal, e fornecer
        seus dados de cobrança e/ou cartão de crédito aos parceiros de
        intermediação financeira da G3, o Usuário autoriza a cobrança recorrente
        pela licença, sempre que vencido o período temporário de validade da
        licença. A cobrança seguirá os valores publicados no Portal, e aceitos
        pelo Usuário no ato da contratação da licença de uso do Produto,
        comprometendo-se o Usuário, assim, ao pagamento do valor periódico da
        licença de uso do Produto.
      </li>
      <li>
        Os Produtos licenciados através do Portal serão constantemente
        atualizados, de forma automática, sempre que houver lançamento de nova
        versão. Assim, em decorrência de atualizações de versão, poderá ocorrer,
        a qualquer tempo, mudanças nos Produtos licenciados.
      </li>
      <li>
        Sem prejuízo de modificações em decorrência de atualizações, a G3 e/ou o
        Licenciante poderão, a qualquer tempo, alterar a forma de fornecimento
        de qualquer Produto, a seu exclusivo critério, e independentemente de
        aviso prévio, com o que anui o Usuário. Adicionalmente, a G3 e/ou o
        Licenciante poderão, a qualquer tempo, estabelecer limites no uso do
        Produto, a seu exclusivo critério, que serão comunicados previamente ao
        Usuário.
      </li>
      <li>
        Os Produtos disponíveis no Portal são licenciados ao Usuário da forma
        como estão, não existindo opção de qualquer customização para as
        necessidades do Usuário. O Usuário deverá assegurar que o produto, da
        forma como está disponível, atende às suas necessidades, não podendo
        imputar qualquer responsabilidade à G3 ou ao Licenciante, caso
        verifique, no futuro, que o produto licenciado não atende à totalidade
        de suas necessidades.
      </li>
      <li>
        Qualquer dano, a qualquer título, decorrente de mau uso, acidente,
        imperícia, imprudência ou negligência na operação, pelo Usuário, dos
        programas de computador licenciados, é de exclusiva responsabilidade do
        Usuário, sem qualquer responsabilidade, solidária ou subsidiária, e a
        qualquer título, da G3 ou do Licenciante.
      </li>
      <li>
        A G3 e/ou o Licenciante não assumem qualquer responsabilidade por danos
        ao Usuário, decorrentes de tentativas de reparo ou alterações não
        autorizadas realizadas pelo Usuário nos programas licenciados, bem como
        invasões no sistema, vírus, e outros elementos nocivos que deverão ser
        garantidos através de recursos de segurança pelo Usuário em seus
        equipamentos de hardware ou software.
      </li>
      <li>
        A G3 e/ou o Licenciante não assumem qualquer responsabilidade pelas
        decisões do Usuário, baseadas no uso dos programas de computador
        licenciados, inclusive decisões fiscais, que são tomadas exclusivamente
        à critério do Usuário, sem qualquer interferência da G3.
      </li>
    </ol>
    <p class="text-justify">
      O Usuário declara estar ciente que pequenos defeitos de programação
      (conhecidos no ramo de tecnologia como “bugs”) são comuns a todos os
      programas de computador, isentando, assim, a G3 e o Licenciante, de
      qualquer responsabilidade por danos decorrentes de tais bugs usuais,
      limitando a responsabilidade da Licenciante à correção desses pequenos
      defeitos de programação.
    </p>
    <p class="text-justify">
      Os direitos não expressamente garantidos ao Usuário, através destes Termos
      são reservados à G3 e/ou ao Licenciante do programa de computador
      adquirido pelo Usuário.
    </p>
    <!-- #endregion -->

    <!-- #region GARANTIAS DE NÍVEIS DE SERVIÇO -->
    <h3 class="texto-dark-minus-1">GARANTIAS DE NÍVEIS DE SERVIÇO</h3>
    <p class="text-justify">
      Para os Produtos e/ou Serviços fornecidos mediante pagamento, a G3 garante
      ao Usuário a disponibilidade de 99,9% (noventa e nove virgular nove por
      cento) do tempo ao mês, calculada de acordo com a seguinte fórmula:
    </p>
    <p class="text-center font-bold">PAM = (TMM - TMP)/TMM</p>
    <p class="text-center font-bold">
      PAM = Porcentagem do tempo de atividade do mês
    </p>
    <p class="text-center font-bold">TMM = Total de minutos no mês</p>
    <p class="text-center font-bold">
      TMP = Total de minutos de paralisação no mês
    </p>
    <p class="text-justify">
      Caso a disponibilidade, em qualquer mês, seja inferior à garantida acima,
      a G3 concorda em conceder ao Usuário os seguintes créditos:
    </p>
    <p class="text-center font-bold">Porcentagem de Atividade do Mês</p>
    <p class="text-center font-bold">Nível de Crédito</p>
    <p class="text-center font-bold">&lt; 99,9%</p>
    <p class="text-center font-bold">10% sobre o valor do mês.</p>
    <p class="text-center font-bold">&lt; 99%</p>
    <p class="text-center font-bold">20% sobre o valor do mês.</p>
    <p class="text-center font-bold">&lt; 95%</p>
    <p class="text-center font-bold">40% sobre o valor do mês.</p>
    <p class="text-justify">
      Os níveis de crédito descritos na tabela acima não são cumulativos e
      representam o recurso exclusivo do Usuário, em caso de descumprimento dos
      níveis de serviço, até pela natureza do serviço prestados, que poderá ser
      afetado por diversos fatores alheios à vontade da G3, conforme prevê o
      artigo 8, do Código de Defesa do Consumidor. Fica ajustado, ainda, que o
      crédito concedido jamais excederá, em nenhuma circunstância, o valor
      mensal pago pelo Usuário pelo Produto ou Serviço afetado pelo
      descumprimento do nível de serviço.
    </p>
    <p class="text-justify">
      Para fazer jus aos créditos previstos acima, o Usuário deverá enviar
      solicitação formal à g3, através do e-mail
      <a href="suporte@g3soft.com.br" class="link-texto"
        >suporte@g3soft.com.br</a
      >, indicando a data e hora da(s) ocorrência(s) que importou(aram) em
      descumprimento do nível de serviço. Dentro de 10 (dez) dias após a
      solicitação do Usuário, a g3 averiguará a eventual procedência da
      solicitação, e, se procedente, informará ao Usuário o nível de crédito,
      que será calculado sobre o valor do mês em que ocorreu o descumprimento do
      nível de serviço, e descontado da fatura com vencimento no mês
      subsequente.
    </p>
    <p class="text-justify">
      Não serão computados como minutos de paralisação no mês, para efeito de
      cálculo da porcentagem do tempo de atividade do mês, as paradas
      decorrentes de manutenção preventiva, ou de qualquer ocorrência alheia ao
      controle e diligência da g3, tais como falta de energia fornecida pela
      concessionária, falhas nas operadoras interconectadas à rede, eventos de
      força maior e caso fortuito, entre outros, e estes não se limitando.
    </p>
    <p class="text-justify">
      Serviços de suporte técnico serão fornecidos pela g3 através dos meios
      divulgados em
      <a href="https://www.g3soft.com.br/suporte/" class="link-texto"
        >https://www.g3soft.com.br/suporte/</a
      >, regularmente de segunda a sexta-feira, das 8:00 às 18:00 horas, com
      tempo de resposta de até 24 (vinte e quatro) horas, não havendo quaisquer
      serviços de suporte técnico nas dependências do Usuário inclusos. Caso o
      Usuário tenha interesse, poderá contratar Serviços de suporte técnico
      estendido (“Suporte Estendido”), conforme preços e condições estabelecidos
      pela G3 na respectiva proposta comercial. Nenhum Produto comercializado no
      Portal inclui serviços de treinamento e/ou packs de consultoria, os quais
      deverão ser contratados separadamente pelo Usuário, caso tenha interesse.
    </p>
    <p class="text-justify">
      Caso sejam contratados pelo Usuário, os serviços de treinamento e/ou packs
      de consultoria deverão ser concluídos em até 90 (noventa) dias, contados
      da data de contratação do treinamento e/ou pack de consultoria. Após esse
      período, os serviços não estarão mais disponíveis ao Usuário. Em nenhuma
      hipótese haverá a devolução dos valores pagos pelo Usuário em virtude de
      serviços disponibilizados pela G3 e não utilizados pelo Usuário.
    </p>
    <!-- #endregion -->

    <!-- #region 6. PROTEÇÃO DE DADOS, PRIVACIDADE E SIGILO -->
    <h3 class="texto-dark-minus-1">
      6. PROTEÇÃO DE DADOS, PRIVACIDADE E SIGILO
    </h3>
    <p class="text-justify">
      As Partes declaram que cumprem toda a legislação brasileira sobre proteção
      de dados e privacidade, inclusive a Constituição Federal, o Código de
      Defesa do Consumidor, o Código Civil, o Marco Civil da Internet (Lei
      Federal n. 12.965/2014), seu decreto regulamentador (Decreto 8.771/2016),
      e se obrigam a cumprir a Lei nº 13.709, de 14 de agosto de 2018 (“LGPD”) e
      outras leis aplicáveis às atividades das Partes relacionadas à proteção de
      dados e privacidade e tomam medidas para garantir que seus empregados,
      agentes e subcontratados também o façam.
    </p>
    <p class="text-justify">
      As Partes garantem que todos os dados pessoais eventualmente
      compartilhados no âmbito deste Termo foram obtidos legalmente de acordo
      com os requisitos da LGPD e que possuem o direito de tratá-los e de
      compartilhá-los com a outra Parte.
    </p>
    <p class="text-justify">
      Em virtude da natureza do Portal, a G3 terá de notificar o Usuário
      periodicamente, sobre anúncios importantes relativos à operação do Portal.
      O Usuário consente com o recebimento de ofertas da G3, e de Parceiros,
      durante o uso do Portal, baseadas no Perfil do Usuário, previamente
      coletado pela G3, declarando que tal fato não resulta em qualquer violação
      de sua privacidade.
    </p>
    <p class="text-justify">
      A G3 se compromete a manter em sigilo os dados que forem inseridos pelo
      Usuário, em áreas restritas do Portal, durante a utilização de Produtos
      licenciados, abstendo-se de divulgá-los a terceiros.
    </p>

    <!-- #endregion -->

    <!-- #region 7. LIMITAÇÃO E ISENÇÃO DE RESPONSABILIDADE -->
    <h3 class="texto-dark-minus-1">
      7. LIMITAÇÃO E ISENÇÃO DE RESPONSABILIDADE
    </h3>
    <p class="text-justify">
      É de integral responsabilidade do Usuário a manutenção de contrato com
      qualquer provedor de serviços de Internet, para o fornecimento dos
      recursos mínimos necessários ao acesso ao Portal, e aos Produtos e
      Serviços contratados, sendo igualmente de responsabilidade do Usuário a
      aquisição e manutenção de equipamentos de software, hardware e quaisquer
      outros, que atendam aos requisitos mínimos necessários de acesso ao
      Portal, e aos Produtos e Serviços Contratados.
    </p>
    <p class="text-justify">
      A G3 não se responsabiliza por perdas e danos causados pela utilização
      irregular do Portal, seus Produtos e Serviços, ou pela utilização
      inadequada dos equipamentos do Usuário, nem por falhas na instalação
      elétrica do Usuário, que deverá ser adequada aos equipamentos utilizados
      para acesso ao Portal, excluindo de sua responsabilidade, ainda, a
      ocorrência de efeitos de caso fortuito e força major.
    </p>
    <p class="text-justify">
      É de responsabilidade exclusiva do Usuário verificar a adequação do(s)
      programa(s) de computador licenciado(s) ao seu ramo de atividade,
      notadamente às regras tributárias e fiscais, municipais, estaduais e
      federais aplicáveis ao Usuário, uma vez que o(s) programa(s) de computador
      licenciado(s) é(são) fornecido(s) sem qualquer customização ou
      parametrização para a localidade e o ramo de atividade específico do
      Usuário, não havendo garantias a qualquer título, implícitas ou
      explícitas, da G3 e/ou do Licenciante, quanto a adequação do(s)
      programa(s) de computador à legislação vigente e aplicável a atividade do
      Usuário.
    </p>
    <p class="text-justify">
      Também não são de responsabilidade da G3 quaisquer perdas e danos
      suportados pelo Usuário em função de problemas software, hardware,
      sistemas, e aplicativos do Usuário, fornecidos por terceiros, bem como
      perdas de dados, invasões, vírus, e qualquer outro evento que fuja ao
      controle e diligência da G3, sendo o Usuário inteiramente responsável por
      sua conta para acesso ao Portal, bem como por seus dados de logins e
      senhas para acesso em páginas de terceiros.
    </p>
    <p class="text-justify">
      O backup realizado pela G3 possui a finalidade exclusiva de garantir a
      restauração dos serviços em caso de pane nos sistemas de armazenamento,
      não permitindo restauração de dados danificados por falhas no uso do
      Produto licenciado. Entretanto, uma cópia de segurança poderá ser baixada
      pelo usuário nos formatos disponibilizados pelo Produto licenciado.
    </p>
    <p class="text-justify">
      O Usuário reconhece que a G3 não controla nem aprova conteúdo que o
      Usuário adiciona aos Produtos por ele utilizados, que será de
      responsabilidade integral do Usuário, sem qualquer responsabilidade da G3.
    </p>
    <p class="text-justify">
      Caso ocorra indenização decorrente dos Produtos e Serviços
      disponibilizados pela G3, por danos diretos ou indiretos, se e quando
      existir alguma responsabilidade comprovada, ficará limitada ao valor total
      pago pelo Usuário à G3, desde o início da contratação de qualquer Produto
      ou Serviço no Portal, até a data do prejuízo, ainda que eventual prejuízo
      exceda tal valor.
    </p>
    <!-- #endregion -->

    <!-- #region 8. PROPRIEDADE INTELECTUAL E INDUSTRIAL -->
    <h3 class="texto-dark-minus-1">8. PROPRIEDADE INTELECTUAL E INDUSTRIAL</h3>
    <p class="text-justify">
      Pertencem exclusivamente aos titulares dos Produtos os direitos de
      propriedade industrial e intelectual sobre os Produtos licenciados através
      do Portal, cujo uso será permitido ao Usuário, de acordo com estes Termos.
    </p>
    <p class="text-justify">
      O Usuário não poderá ter acesso aos códigos fonte dos Produtos, nem a
      camadas fechadas dos Produtos, ficando ciente que a tentativa de acesso a
      esses itens, ou o efetivo acesso a esses itens, por qualquer meio,
      constituirá violação de direitos autorais do titular dos direitos sobre o
      Produto, sujeitando o Usuário às sanções previstas em Lei.
    </p>
    <p class="text-justify">
      O Usuário não poderá, direta ou indiretamente, inclusive através de atos
      de seus prepostos, copiar, modificar, transferir, sublicenciar, ceder,
      vender, dar em locação, dar em garantia, doar, ou alienar a terceiros, os
      Produtos a que tiver acesso através do Portal, sob qualquer forma ou
      modalidade, gratuita ou onerosa, provisória ou permanente, no todo ou em
      parte, nem submetê-los a processos de engenharia reversa ou, ainda,
      traduzi-lo ou decodificá-lo, no todo ou em parte. As mesmas vedações
      aplicam-se a quaisquer documentações ou informações relativas aos
      Produtos.
    </p>
    <p class="text-justify">
      A G3 pode citar o nome e marca do Usuário, como fonte de referência e/ou
      usuário do Portal, seus Serviços e Produtos, inclusive em sua publicidade,
      independentemente de qualquer pagamento adicional, com o que consente o
      Usuário.
    </p>
    <p class="text-justify">
      O Usuário se compromete a não utilizar as especificações dos Produtos, ou
      permitir que terceiros as utilizem, com vistas a criar outros de mesma
      destinação.
    </p>
    <p class="text-justify">
      A G3 não garante que os Produtos operarão em combinações com outras, que
      não aquelas para as quais foram especificados os Produtos, e que sua
      operação será ininterrupta ou livre de erros.
    </p>
    <p class="text-justify">
      A responsabilidade da G3 e/ou do Licenciante, em relação aos Produtos,
      ficará limitada à correção dos eventuais erros encontrados nos Produtos,
      assegurando ao Usuário a devolução dos valores pagos, quando aplicável,
      caso a G3 e/ou o Licenciante não possam fazer com que o Produto opere
      conforme garantido.
    </p>
    <p class="text-justify">
      Os nomes, marcas e logotipos da G3, do Licenciante, ou de qualquer outro
      terceiro, contidas nos Produtos e Serviços, e qualquer documentação que os
      acompanhe, não poderão ser adulterados ou modificados, bem como não
      poderão ser objeto de venda, licenciamento, doação, locação ou qualquer
      forma de transferência ou transmissão, onerosa ou gratuita, permanente ou
      temporária.
    </p>
    <!-- #endregion -->

    <!-- #region 9. PRAZO E RESCISÃO -->
    <h3 class="texto-dark-minus-1">9. PRAZO E RESCISÃO</h3>
    <p class="text-justify">
      A relação entre a G3 e o Usuário, nesses Termos, e de eventuais outras
      condições adicionais que a ela possam se acrescer, será por prazo
      indeterminado, podendo ser interrompida a qualquer tempo.
    </p>
    <p class="text-justify">
      A simples desistência de uso pelo Usuário não gera o cancelamento ou a
      rescisão do seu contrato com a G3.
    </p>
    <p class="text-justify">
      Não havendo interesse do Usuário em continuar a utilizar quaisquer
      Serviços e Produtos disponibilizados no Portal, deverá o Usuário
      formalizar, expressamente, sua intenção de cancelamento do Serviço ou
      Produto, mediante acesso à área restrita do Portal, com a utilização de
      seu nome de usuário e senha, onde deverá realizar a solicitação de
      cancelamento do Serviço e/ou Produto, através de opção própria para
      cancelamento.
    </p>
    <p class="text-justify">
      O cancelamento se tornará efetivo na data em que se encerrar o ciclo
      temporário de validade da licença de uso em vigor, já pago anteriormente
      pelo Usuário, que valerá como aviso prévio do Usuário.
    </p>
    <p class="text-justify">
      Dentro desse período, o Usuário poderá utilizar livremente os Serviços
      e/ou Produtos contratados, que permanecerão disponíveis.
    </p>
    <p class="text-justify">
      Caso a G3 e/ou o Licenciante de qualquer Produto ou Serviço disponível
      através do Portal, resolva por descontinuar qualquer Produto ou Serviço,
      tal decisão será informada ao Usuário com antecedência de 90 (noventa)
      dias, que valerá como aviso prévio, durante o qual o Usuário poderá
      utilizar livremente o Produto ou Serviço em processo de descontinuidade,
      mediante o pagamento dos valores correspondentes. Poderá ocorrer exceção
      ao aviso prévio diante de decisões judiciais, administrativas ou
      determinações de autoridades regulatórias competentes, hipótese em que a
      descontinuidade poderá ocorrer de forma automática.
    </p>
    <p class="text-justify">
      Em qualquer hipótese de rescisão, solicitada por qualquer das partes, não
      incidirão quaisquer cláusulas penais, ou dever de indenizar, ficando os
      ônus da parte que solicitou a rescisão limitados ao aviso prévio previsto
      nos parágrafos antecedentes.
    </p>
    <p class="text-justify">
      No caso de qualquer descumprimento destes Termos, ou de quaisquer outras
      condições associadas a qualquer Produto ou Serviço disponibilizado pelo
      Portal, o acesso do Usuário também poderá ser interrompido, com a rescisão
      desses Termos e de qualquer outro contrato com o Usuário, mediante aviso
      prévio de 48 (quarenta e oito) horas.
    </p>
    <p class="text-justify">
      Durante o período de aviso prévio, o Usuário deverá efetuar o download de
      todos os seus dados, que eventualmente estejam armazenados nos Produtos ou
      Serviços em período de aviso prévio, visando criar uma cópia de segurança
      desses dados, pois a G3 e/ou o Licenciante irão excluir todo e qualquer
      dado do Usuário que esteja armazenado em seus servidores, imediatamente
      após o encerramento do aviso prévio, e suspensão do acesso ao Usuário,
      sendo tais dados irrecuperáveis, após a remoção realizada, o que declara
      estar ciente o Usuário. Em caso de exceção ao aviso prévio o Usuário será
      comunicado sobre o prazo em que o download estará disponível.
    </p>
    <p class="text-justify">
      O download destes dados poderá ser realizado dentro da área restrita do
      Portal, mediante acesso da área de acesso para backup, e estará disponível
      nos formatos definidos pelo Programa, que podem ser consultados na mesma
      área.
    </p>
    <p class="text-justify">
      No caso de ser negado o pagamento recorrente de qualquer novo período de
      licença dos Produtos fornecidos mediante pagamento no Portal, o acesso a
      esses produtos será imediatamente suspenso, sendo que, não havendo
      regularização dos pagamentos pelo Usuário, após o prazo de 15 (dias) dias,
      o acesso ao Produto não pago será automaticamente cancelado, com a
      rescisão da contratação, e consequente deleção de todos os dados
      eventualmente inseridos pelo Usuário no Produto, sem que o Usuário tenha
      direito a qualquer indenização, a qualquer título.
    </p>
    <p class="text-justify">
      O Usuário reconhece e declara que, salvo o expressamente descrito nesses
      Termos, a G3 não terá nenhuma obrigação, de qualquer natureza, após
      expirado o aviso prévio de qualquer rescisão contratual, por qualquer
      motivo, reconhecendo o Usuário que a caso solicitado pelo Usuário a G3
      excluirá todos de dados do Usuário inseridos e trafegados pelo Portal,
      mantendo apenas o que eventualmente for necessário para atendimento de
      obrigação legal em vigor.
    </p>
    <!-- #endregion -->

    <!-- #region 10. CORREÇÃO MONETÁRIA, FATURAMENTO E ENQUADRAMENTO FISCAL -->
    <h3 class="texto-dark-minus-1">
      10. CORREÇÃO MONETÁRIA, FATURAMENTO E ENQUADRAMENTO FISCAL
    </h3>
    <p class="text-justify">
      Os preços pagos pelo Usuário por eventuais Serviços fornecidos mediante
      pagamento, serão corrigidos monetariamente, a cada 12 (doze) meses, ou na
      menor periodicidade que venha a ser permitida pela legislação em vigor,
      com base na variação positiva do IGPM/FGV, ou outro índice que venha a
      substituí-lo, no caso de sua extinção. Os preços pagos pelo Usuário por
      eventuais Serviços fornecidos mediante pagamento, serão reavaliados e
      enquadrados conforme tabela de preços vigente a cada 3 (três) meses, com
      base no faturamento e no enquadramento fiscal da empresa do Usuário,
      observada a legislação em vigor.
    </p>
    <!-- #endregion -->

    <!-- #region 11. RESPONSABILIDADE TRIBUTÁRIA E TRIBUTAÇÃO -->
    <h3 class="texto-dark-minus-1">
      11. RESPONSABILIDADE TRIBUTÁRIA E TRIBUTAÇÃO
    </h3>
    <p class="text-justify">
      Os tributos, impostos, taxas, emolumentos, contribuições fiscais e
      parafiscais que sejam devidos em decorrência, direta ou indireta do
      contrato ou de sua execução, serão de exclusiva responsabilidade do
      contribuinte prestador de serviço assim definido na norma tributária, sem
      direito a reembolso.
    </p>
    <p class="text-justify">
      Os preços pagos pelo Usuário incluem todos os tributos cabíveis, de acordo
      com a legislação vigente. Eventuais aumentos nos impostos incidentes sobre
      os Serviços e/ou Produtos, objeto do presente Termo, ou a criação de novos
      tributos, serão automaticamente repassados ao Usuário, visando à
      manutenção do equilíbrio econômico-financeiro estabelecido na presente
      contratação.
    </p>
    <!-- #endregion -->

    <!-- #region 12. PRÁTICAS ANTICORRUPÇÃO -->
    <h3 class="texto-dark-minus-1">12. PRÁTICAS ANTICORRUPÇÃO</h3>
    <p class="text-justify">
      As Partes declaram conhecer as normas de prevenção à corrupção previstas
      na legislação brasileira, dentre elas o Código Penal Brasileiro, a Lei
      12.846/13 (Lei Anticorrupção), a Lei 9.613/98 e todos os demais
      instrumentos regulatórios de combate à Lavagem de Dinheiro e Financiamento
      ao Terrorismo, obrigando-se a observá-los e a cumpri-los e/ou fazer
      cumprir-se, por si, suas afiliadas, prepostos, diretores, membros do
      conselho da administração, quaisquer terceiros relacionados, bem como
      garantir o cumprimento das determinações estabelecidas nessas normas.
    </p>
    <p class="text-justify">
      As Partes ficam obrigadas a informar uma à outra, imediatamente, caso seja
      iniciada qualquer investigação de suas atividades com base em quaisquer
      Leis Anticorrupção e/ou Lavagem de dinheiro. Para o cumprimento do
      disposto nesta Cláusula a G3 disponibiliza o seu Canal de Denúncias para
      comunicação, administrado por empresa independente, com garantia de
      anonimato e não retaliação ao denunciante, para o esclarecimento de
      dúvidas ou relatos de suspeitas de violação ao Código de Ética e Conduta
      da G3 e /ou Leis Anticorrupção, que poderá ocorrer das seguintes formas:
    </p>
    <p class="text-justify font-bold">Canal de Denúncias G3</p>
    <p class="text-justify font-bold">
      <a href="ouvidoria@g3soft.com.br" class="link-texto"
        >ouvidoria@g3soft.com.br</a
      >
    </p>
    <!-- #endregion -->

    <!-- #region 13. DISPOSIÇÕES FINAIS -->
    <h3 class="texto-dark-minus-1">13. DISPOSIÇÕES FINAIS</h3>
    <p class="text-justify">
      É vedado ao Usuário ceder ou transferir a terceiros os direitos e
      obrigações decorrentes do presente instrumento, total ou parcialmente,
      salvo se com a concordância prévia e expressa da G3.
    </p>
    <p class="text-justify">
      Todas as comunicações entre as partes poderão ser realizadas por carta,
      fax, ou e-mail, o que as partes declaram aceitar, neste ato, ficando
      cientes que a validade jurídica de todas as comunicações fica condicionada
      a existência de protocolo, ou aviso de recebimento, sob pena de serem
      tidas como não entregues.
    </p>
    <p class="text-justify">
      No caso de um tribunal ou juízo competente julgar ilegal, nula ou ineficaz
      qualquer estipulação destes Termos, as estipulações restantes permanecerão
      em plena força e vigor. Caso a estipulação ou estipulações julgadas
      ilegais, nulas ou ineficazes sejam de ordem a afetar substantivamente o
      equilíbrio das partes perante o presente acordo, elas deverão negociar, de
      boa-fé, uma alternativa que, não contendo os vícios da estipulação ou
      estipulações invalidadas, reflitam, na maior extensão possível, as suas
      intenções originárias.
    </p>
    <p class="text-justify">
      A tolerância de uma parte para com a outra, relativamente a descumprimento
      de qualquer das obrigações assumidas, não será considerada novação ou
      renúncia a qualquer direito, constituindo mera liberalidade, que não
      impedirá a parte tolerante de exigir da outra o fiel cumprimento destes
      Termos, a qualquer tempo.
    </p>
    <p class="text-justify">
      No caso de uso de Produtos fora do território brasileiro, o Usuário
      concorda em cumprir, de forma integral, todas as Leis e Regulamentos de
      exportação do Brasil, e dos países de destino dos Produtos em eventual
      utilização, que é restrita a empresas do mesmo grupo econômico do Usuário,
      alocadas fora do país, e mediante prévia e expressa autorização da G3,
      para assegurar que nem os Programas, nem qualquer outro produto deles
      diretamente decorrente (1) sejam exportados, direta ou indiretamente, em
      violação às Leis de Exportação; ou (2) sejam obtidos com a intenção de
      serem usados para quaisquer propósitos proibidos pelas Leis de Exportação.
    </p>
    <p class="text-justify">
      Eventuais atualizações destes Termos serão publicadas no Portal, e
      submetidos à aceitação do Usuário, que, se não concordar com as
      alterações, terá a faculdade de dar por rescindido o contrato, sem
      quaisquer ônus, cessando seu acesso ao Portal, bem como aos Serviços e
      Produtos disponibilizados pela G3.
    </p>
    <p class="text-justify">
      As partes exercem a sua liberdade de contratar, observados os preceitos de
      ordem pública e o princípio da função social do presente contrato, que
      atende também aos princípios da economicidade, razoabilidade e
      oportunidade, permitindo o alcance dos respectivos objetivos societários
      das partes e atividades empresariais, servindo, consequentemente, a toda a
      sociedade.
    </p>
    <p class="text-justify">
      As partes declaram que sempre guardarão, na execução destes Termos, os
      princípios da probidade e da boa-fé, presentes também, tanto na sua
      negociação, quanto na sua celebração.
    </p>
    <!-- #endregion -->

    <!-- #region 14. LEGISLAÇÃO APLICÁVEL E FORO -->
    <h3 class="texto-dark-minus-1">14. LEGISLAÇÃO APLICÁVEL E FORO</h3>
    <p class="text-justify">
      Estes Termos serão regidos e interpretados de acordo com as Leis vigentes
      na República Federativa do Brasil.
    </p>
    <p class="text-justify">
      Elegem as partes o Foro da Batatais-SP, como competente para dirimir
      qualquer dúvida ou controvérsia decorrente dos presentes Termos,
      renunciando a qualquer outro, por mais privilegiado que seja, ou venha a
      ser.
    </p>
    <p class="text-justify font-bold">
      Última atualização 11 de Setembro de 2023.
    </p>
    <!-- #endregion -->
  </div>
</div>
