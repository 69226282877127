<p-toast></p-toast>
<p-dialog
  header=" "
  [(visible)]="showDialog"
  [modal]="true"
  [style]="{ width: isDesktop ? '30vw' : '90vw' }"
  [draggable]="true"
  [resizable]="true"
  (onShow)="resetForm()"
  (onHide)="aoFechar()"
>
  <div class="form mx-5" [formGroup]="form">
    <div class="dialog-header">
      <span class="titulo">Fale Conosco</span>
      <span class="subtitulo">Solicite retorno do setor comercial</span>
    </div>

    <div class="formgrid grid">
      <div class="field col-12">
        <input
          placeholder="Nome"
          pInputText
          formControlName="nome"
          type="text"
          class="inputfield w-full"
          [ngClass]="{
            'ng-invalid ng-dirty': verificarCampoInvalido(form, 'nome')
          }"
        />
        <small
          *ngIf="verificarCampoInvalido(form, 'nome', 'required')"
          class="p-error"
          >Campo obrigatório<br
        /></small>
      </div>
      <div class="field col-12">
        <input
          placeholder="E-mail"
          pInputText
          formControlName="email"
          type="text"
          class="inputfield w-full"
          [ngClass]="{
            'ng-invalid ng-dirty': verificarCampoInvalido(form, 'email')
          }"
        />
        <small
          *ngIf="verificarCampoInvalido(form, 'email', 'required')"
          class="p-error"
          >Campo obrigatório<br
        /></small>
      </div>
      <div class="field col-12">
        <input
          placeholder="CPF/CNPJ"
          pInputText
          formControlName="documento"
          type="text"
          class="inputfield w-full"
          [mask]="maskDocumento"
          [ngClass]="{
            'ng-invalid ng-dirty': verificarCampoInvalido(form, 'documento')
          }"
        />
        <small
          *ngIf="verificarCampoInvalido(form, 'documento', 'required')"
          class="p-error"
          >Campo obrigatório<br
        /></small>
      </div>
      <div class="field col-12">
        <input
          placeholder="Telefone"
          pInputText
          formControlName="telefone"
          type="text"
          [mask]="maskTelefone"
          class="inputfield w-full"
          [ngClass]="{
            'ng-invalid ng-dirty': verificarCampoInvalido(form, 'telefone')
          }"
        />
        <small
          *ngIf="verificarCampoInvalido(form, 'telefone', 'required')"
          class="p-error"
          >Campo obrigatório<br
        /></small>
      </div>
    </div>
    <p-button
      (onClick)="enviar()"
      label="Enviar"
      class="primary-button ml-1"
      [loading]="loading"
    ></p-button>
  </div>
  <ng-template pTemplate="footer">
    <div class="dialog-footer">
      <div class="email">
        <a [href]="hrefContatoEmail">{{ contatoEmail }}</a>
      </div>
    </div>
  </ng-template>
</p-dialog>
