<app-contato-header></app-contato-header>
<!-- <app-mini-header></app-mini-header> -->
<nav class="header container">
  <div class="logo">
    <a routerLink="/"
      ><img
        src="assets/imagens/g3-logo-dark-simplifcada-512x128.png"
        alt=""
        height="30"
        class="p-mr-2"
    /></a>
  </div>
  <div class="menu">
    <ul class="menu-list">
      <!-- #region SOLUÇÕES -->
      <li class="menu-item">
        <a [routerLink]="['/solucoes']"
          >SOLUÇÕES<i class="menu-item-icon pi pi-chevron-down"></i
        ></a>
        <ul class="submenu solucoes">
          <ng-container *ngFor="let solucao of solucoes">
            <li class="submenu-item-solucao">
              <div>
                <img [src]="solucao.imgSrc" alt="" height="30" class="p-mr-2" />
              </div>
              <span>{{ solucao.text }} </span>
              <button [routerLink]="[solucao.routerLink]">Saiba +</button>
            </li>
            <p-divider layout="vertical" *ngIf="solucao.separator"></p-divider>
          </ng-container>
        </ul>
      </li>
      <!-- #endregion -->

      <!-- #region FUNCIONALIDADES -->
      <li class="menu-item">
        <a [routerLink]="['/funcionalidades']"
          >FUNCIONALIDADES<i class="menu-item-icon pi pi-chevron-down"></i
        ></a>
        <ul class="submenu funcionalidades">
          <ng-container *ngFor="let funcionalidade of funcionalidades">
            <li class="submenu-item-funcionalidade">
              <h2>{{ funcionalidade.title }}</h2>
              <ng-container *ngFor="let item of funcionalidade.items">
                <span>{{ item.title }}</span>
              </ng-container>
            </li>
            <p-divider
              layout="vertical"
              *ngIf="funcionalidade.separator"
            ></p-divider>
          </ng-container>
        </ul>
      </li>
      <!-- #endregion -->

      <!-- #region SEGMENTOS -->
      <li class="menu-item">
        <a [routerLink]="['/segmentos']"
          >SEGMENTOS<i class="menu-item-icon pi pi-chevron-down"></i
        ></a>
        <ul class="submenu segmentos">
          <li class="submenu-item-segmento">
            <ng-container *ngFor="let segmento of segmentos.slice(0, 5)">
              <span class="flex align-items-center">
                <img *ngIf="segmento.icon" [src]="segmento.icon" width="30px" />
                <a [routerLink]="[segmento.routerLink]" class="pl-3">{{
                  segmento.title
                }}</a></span
              >
            </ng-container>
          </li>
          <p-divider layout="vertical"></p-divider>
          <li class="submenu-item-segmento">
            <ng-container *ngFor="let segmento of segmentos.slice(5, 10)">
              <span class="flex align-items-center">
                <img *ngIf="segmento.icon" [src]="segmento.icon" width="30px" />
                <a [routerLink]="[segmento.routerLink]" class="pl-3">{{
                  segmento.title
                }}</a></span
              >
            </ng-container>
          </li>
          <p-divider layout="vertical"></p-divider>
          <li class="submenu-item-segmento">
            <ng-container *ngFor="let segmento of segmentos.slice(10, 15)">
              <span class="flex align-items-center">
                <img *ngIf="segmento.icon" [src]="segmento.icon" width="30px" />
                <a [routerLink]="[segmento.routerLink]" class="pl-3">{{
                  segmento.title
                }}</a></span
              >
            </ng-container>
          </li>
          <p-divider layout="vertical"></p-divider>
          <li class="submenu-item-segmento">
            <ng-container *ngFor="let segmento of segmentos.slice(15, 20)">
              <span class="flex align-items-center">
                <img *ngIf="segmento.icon" [src]="segmento.icon" width="30px" />
                <a [routerLink]="[segmento.routerLink]" class="pl-3">{{
                  segmento.title
                }}</a></span
              >
            </ng-container>
          </li>
        </ul>
      </li>
      <!-- #endregion -->

      <!-- #region PARCEIRAS -->
      <li class="button-parcerias">
        <p-button
          label="SEJA PARCEIRO"
          routerLink="/parcerias"
          class="on-light-button-outline ml-1"
        ></p-button>
      </li>
      <!-- #endregion -->
      <li class="button-sidebar">
        <p-button
          styleClass="p-button-text texto-primary"
          icon="pi pi-bars"
          (onClick)="aoClicarMenu()"
        ></p-button>
      </li>
    </ul>
  </div>
</nav>
