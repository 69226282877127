import { Injectable } from '@angular/core';
import { TemaService } from '@app/infra/services/tema.service';
import { TemaState } from '@app/infra/states/tema.states';

@Injectable({ providedIn: 'root' })
export class TemaFacade {
  get tema() {
    return this.state.tema;
  }
  constructor(private state: TemaState, private service: TemaService) {}
  setarTema(tema: string) {
    if (this.tema) {
      document.body.classList.remove(this.tema);
    } else {
      document.body.classList.remove('light');
    }
    document.body.classList.add(tema);
    this.service.setarTema(tema);
  }
}
