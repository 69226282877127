<nav class="contato-header container">
  <div class="slogan">
    <span>Contrate agora e GANHE <span class="desconto">50% OFF</span> na implantação. </span>
  </div>
  <div class="contato">
    <a
      href="https://api.whatsapp.com/send?phone=16992249258&text=Ol%C3%A1%2C%20gostaria%20de%20saber%20mais%20sobre%20as%20solu%C3%A7%C3%B5es%20da%20G3Soft"
      target="_blank"
    >
      <p-button class="primary-button ml-1">GARANTA AGORA! </p-button>
    </a>
  </div>
</nav>
