import {Component, HostListener, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MenuItem} from 'primeng/api';

import {TemaFacade} from './domain/facades/tema.facade';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  mostrarSidebar = false;

  items: MenuItem[] = [
    {
      label: 'Home',
      command:
          () => {
            this.router.navigate(['/']);
            this.toggleSidebar();
          },
    },
    {
      label: 'Soluções',
      command:
          () => {
            this.router.navigate(['/solucoes']);
            this.toggleSidebar();
          },
    },
    {
      label: 'Funcionalidades',
      command:
          () => {
            this.router.navigate(['/funcionalidades']);
            this.toggleSidebar();
          },
    },
    {
      label: 'Segmentos',
      command:
          () => {
            this.router.navigate(['/segmentos']);
            this.toggleSidebar();
          },
    },
    {
      label: 'Parcerias',
      command:
          () => {
            this.router.navigate(['/parcerias']);
            this.toggleSidebar();
          },
    },
  ];
  constructor(
      private temaFacade: TemaFacade,
      private router: Router,
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (event.currentTarget.innerWidth > 800) {
      this.mostrarSidebar = false;
    }
  }
  ngOnInit() {
    if (this.temaFacade.tema) {
      this.temaFacade.setarTema(this.temaFacade.tema);
    } else {
      localStorage.setItem('app_tema', 'light');
      this.temaFacade.setarTema('light');
    }
  }
  toggleSidebar() {
    this.mostrarSidebar = !this.mostrarSidebar;
  }
}
