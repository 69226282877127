import {Component} from '@angular/core';
import {AppFacade} from '@app/domain/facades/app.facade';

@Component({
  selector: 'app-contato-header',
  templateUrl: './contato-header.component.html',
  styleUrls: ['./contato-header.component.scss'],
})
export class ContatoHeaderComponent {
  get contatoEmail() {
    return this.appFacade.contatoEmail;
  }
  get hrefContatoEmail() {
    return `mailto:${this.contatoEmail}`;
  }
  constructor(private appFacade: AppFacade) {}
}
